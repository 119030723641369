import { useState, useEffect } from 'react'
import { Auth } from 'aws-amplify'
import moment from 'moment'
import axios from 'axios'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import SalesDate from '../common/SalesDate'
import SalesOrderItemList from './SalesOrderItemList'
import { useParams, NavLink, useNavigate, Link} from 'react-router-dom';

const SalesOrder = () => {
    const initSalesOrderItemData = [{
        internalIndex: 0, 
        species: null, 
        productType: null, 
        brandCode: null, 
        mscCode: null, 
        location: null, 
        billOfLading: null, 
        lotNumber: null, 
        productWeight: null, 
        totalWeight: null, 
        salePrice: null, 
        quantity: null, 
        itemTotal: 0,
        inventoryId: 0,
        preSaleInd: 0
    }]
    const [salesData, setSalesData] = useState([])
    const [salesOrderItemData, setSalesOrderItemData] = useState([])
    const [originalOrderItems, setOriginalOrderItems] = useState([])
    const [salesValues, setSalesValues] = useState([])
    const [salesDate, setSalesDate] = useState(moment())
    const [invoiceDate, setInvoiceDate] = useState()
    // const [paidDate, setPaidDate] = useState()
    const [salesOrderTotal, setSalesOrderTotal] = useState(0)
    const [salesOrderTotalLbs, setSalesOrderTotalLbs] = useState(0)
    const [buttonLabel, setButtonLabel] = useState('Submit Sales Order')
    const [errorMsg, setErrorMsg] = useState('')
    const [loading, setLoading] = useState(false)
    const [saving, setSaving] = useState(false)
    const [confirmationPage, setConfirmationPage] = useState(false)
    const [salesOrderId, setSalesOrderId] = useState()
    const [internalIndex, setInternalIndex] = useState(0)
    const [currOrderStatus, setCurrOrderStatus] = useState()
    const [cognitoGroups, setCognitoGroups] = useState()

    const { id }  = useParams();
    let isAddMode = !id
    const navigate = useNavigate()

    // form validation rules 
    const validationSchema = Yup.object().shape({
        salesPerson: Yup.string()
            .required('Sales Person is required'),
        orderStatus: Yup.string()
            .required('Order Status is required'),
        plantFacility: Yup.string()
            .required('Plant is required'),
        customer: Yup.string()
            .required('Customer is required'),
        salesItemData: Yup.array().of(
            Yup.object().shape({
                species: Yup.string().required(),
                product: Yup.string().required(),
                brandCode: Yup.string().required(),
                location: Yup.string().required(),
                quantity: Yup.number().when("weightType", {
                    is: (value) => value === 'VAR' || value === 'FIX',
                    then: Yup.number().min(1),
                    otherwise: Yup.number().nullable()
                }),
                productWeight: Yup.number().when("weightType", {
                    is: 'VAR',
                    then: Yup.number().min(1),
                    otherwise: Yup.number().nullable()
                }),
                totalWeight: Yup.number().min(1).when("weightType", {
                    is: 'BLK',
                    then: Yup.number().min(1),
                    otherwise: Yup.number().nullable()
                }),
                salePrice: Yup.number().min(0.01),
            })
        )
    });

    const { register, unregister, setValue, getValues, handleSubmit, reset, formState: {errors} } = useForm({ 
        mode:"onChange",
        resolver: yupResolver(validationSchema)
    });

    // Get sales values when the page is loaded
    useEffect (() => {
        fetchData()
    }, [])

    const getCognitoGroups = async () => {
        const {accessToken} = await Auth.currentSession()
        setCognitoGroups(accessToken.payload['cognito:groups'])
    }
    const fetchData = async () => {
        setErrorMsg('')
        setLoading(true)
        setSalesValues([])
        let session = await Auth.currentSession()
        let jwt = session.getIdToken().getJwtToken()

        if (isAddMode) {
            console.log('WHEN DO I GET HERE?: ', isAddMode)
            setConfirmationPage(false)
            axios
            .get(`${process.env.REACT_APP_API_URL}/salesvalue?values=all`,
            {headers: {Authorization: jwt}}
            )
            .then(response => {
                setSalesValues(response.data)
                setSalesOrderItemData(initSalesOrderItemData)
                setLoading(false)  
            })
            .catch(error => {
                console.log('The error is: ', error)
                setErrorMsg('Problem retrieving data: ', error)
                setLoading(false)  
            })  
        } else {
            console.log('In UPDATE???: ', isAddMode)
            getCognitoGroups()
            setErrorMsg('')
            setLoading(true)
            setButtonLabel('Update Sales Order')
            let session = await Auth.currentSession()
            let jwt = session.getIdToken().getJwtToken()
            axios
            .get(`${process.env.REACT_APP_API_URL}/salesorder?salesOrderId=${id}`,
            {headers: {Authorization: jwt}}
            )
            .then(response => {
                console.log('WHAT DID I GET: ', response.data)
                setSalesData([])
                setSalesData(response.data.salesOrderData[0])
                setSalesValues(response.data.salesValues)

                let salesData = response.data.salesOrderData[0]
                
                setCurrOrderStatus(salesData.orderStatus)

                setValue('salesPerson', salesData.salesPerson)
                setSalesDate(moment(salesData.salesDate))
                if (salesData.invoiceDate) {
                    setInvoiceDate(moment(salesData.invoiceDate))
                }
                // if (salesData.paidDate) {
                //     setPaidDate(moment(salesData.paidDate))
                // }
                setValue('plantFacility', salesData.plantFacility)
                setValue('customer', salesData.customer)
                setValue('custPurchaseOrder', salesData.custPurchaseOrder)
                setValue('currency', salesData.currency)
                setValue('comments', salesData.comments)
                setValue('orderStatus', salesData.orderStatus)
                setValue('invoiceNotes', salesData.invoiceNotes)
                setValue('invoiceDate', salesData.invoiceDate)
                setValue('invoiceNumber', salesData.invoiceNumber)
                // setValue('paidDate', salesData.paidDate)
                setInternalIndex(response.data.salesOrderItemData.length - 1)
                setSalesOrderItemData(response.data.salesOrderItemData)
                setOriginalOrderItems(response.data.salesOrderItemData.map(newSalesOrderItem => {
                    return newSalesOrderItem
                }))
                console.log('WHAT ORIG??: ', originalOrderItems)

                setLoading(false)
            })
            .catch(error => {
                console.log('The error is: ', error)
                setErrorMsg('Problem retrieving data: ', error)
                setLoading(false)
            })    
        } 
    }    

    // calculate the overall sales order total
    const calculateSalesOrderTotal = () => {
        const totalSalesOrder = salesOrderItemData.reduce((accumulator, object) => {
            return accumulator + object.itemTotal
        }, 0)        
        setSalesOrderTotal(totalSalesOrder)
    }

    const calculateSalesOrderTotalLbs = () => {
        const totalSalesOrderLbs = salesOrderItemData.reduce((accumulator, object) => {
            return accumulator + object.totalWeight
        }, 0)
        setSalesOrderTotalLbs(totalSalesOrderLbs)
    }

    const addSalesItemRow = () => {
        let newSalesOrderItemData = salesOrderItemData
        // let maxInternalIndex = Math.max.apply(Math, salesOrderItemData.map((item) => item.internalIndex))
        // maxInternalIndex++
        let localIndex = internalIndex + 1
        setInternalIndex(localIndex)
        newSalesOrderItemData.push({
            internalIndex: localIndex, 
            species: null, 
            productType: null,  
            brandCode: null, 
            mscCode: null, 
            location: null, 
            billOfLading: null,
            lotNumber: null, 
            productWeight: null, 
            totalWeight: null, 
            salePrice: null, 
            quantity: null, 
            itemTotal: 0,
            inventoryId: 0,
            preSaleInd: 0
        })
        setSalesOrderItemData(newSalesOrderItemData.map(newSalesOrderItem => {
            return newSalesOrderItem
        }))
        console.log('WHATS HERE NOW?: ', originalOrderItems)
    }

    const removeSalesItemRow = async (index, registerIndex) => {
        let newSalesOrderItemData = salesOrderItemData
        newSalesOrderItemData.splice(index,1)
        setSalesOrderItemData(newSalesOrderItemData.map(newSalesOrderItem => {
            return newSalesOrderItem
        }))  
        unregister(`salesItemData.${registerIndex}`)

        calculateSalesOrderTotal()
        calculateSalesOrderTotalLbs()     
    }

    const onSubmit = (data, e) => {
        e.preventDefault()
        createOrder(data)
    }

    const createOrder = async (data) => {
        const salesPersonData = salesValues.employeeRows.filter(employee => employee.employee_id == data.salesPerson)
        const salesPersonName = salesPersonData[0].first_name + ' ' + salesPersonData[0].last_name        
        const plantFacilityName = salesValues.plantRows.filter(plant => plant.plant_id == data.plantFacility)[0].plant_name
        const customer =  salesValues.customerRows.filter(customerRow => customerRow.customer_id == data.customer)[0]
        const customerName = customer.customer_name
        const customerType = customer.customer_type
        const orderStatusDesc = salesValues.orderStatusRows.filter(status => status.status_code == data.orderStatus)[0].status_description

        let salesOrderDetail = {salesPerson: data.salesPerson, salesPersonName, 
            salesDate: salesDate.format('YYYY-MM-DD'),  
            orderStatus: data.orderStatus, orderStatusDesc,
            currOrderStatus,
            invoiceDate: invoiceDate? invoiceDate.format('YYYY-MM-DD') : null,
            invoiceNumber: data.invoiceNumber,
            // paidDate: paidDate? paidDate.format('YYYY-MM-DD') : null,
            plantFacility: data.plantFacility, plantFacilityName,
            customer: data.customer, customerName, customerType,
            custPurchaseOrder: data.custPurchaseOrder, 
            currency: data.currency, salesOrderTotal, salesOrderTotalLbs,
            comments: data.comments, invoiceNotes: data.invoiceNotes}
        if (!isAddMode) {
            salesOrderDetail = {...salesOrderDetail, salesOrderId: id}
        }

        let salesOrderItems = data.salesItemData.filter(item => {
            if (item) return item
        })

        salesOrderItems.forEach((item, index) => {
            salesOrderItems[index]['speciesName'] = salesValues.speciesRows.filter(species => species.species_id == item.species)[0].species_name
            const product = salesValues.productRows.filter(product => product.product_id == item.product)
            salesOrderItems[index]['productName'] = product[0].product_name
            if (product[0].weight_type === 'FIX') {
                salesOrderItems[index]['productWeight'] = product[0].product_weight
            }
            salesOrderItems[index]['brandName'] = salesValues.brandRows.filter(brand => brand.brand_code == item.brandCode)[0].brand_name
            salesOrderItems[index]['locationName'] = salesValues.prodLocationRows.filter(location => location.location_id == item.location)[0].location_name
            if (item.mscCode) {
                salesOrderItems[index]['mscCodeName'] = salesValues.mscRows.filter(msc => msc.msc_id == item.mscCode)[0].msc_code
            }
            if (product[0].weight_type === 'BLK') {
                salesOrderItems[index]['totalWeight'] = item.totalWeight
            } else {
                salesOrderItems[index]['totalWeight'] = item.productWeight * item.quantity
            }
            salesOrderItems[index]['itemTotal'] = item.totalWeight * item.salePrice
            if (item.preSaleInd) {
                salesOrderItems[index]['plantId'] = salesValues.plantRows.filter(plant => plant.plant_name == item.locationName)[0].plant_id
            }
        })

        setErrorMsg('')

        console.log('OLD ITEMS: ', originalOrderItems)
        console.log('NEW STUFF: ', salesOrderItems)

        let updateInvItems = []
        let updatePresaleItems = []

        // if we are updating an order we need to compare old and new to get inventory changes
        if (!isAddMode) {
            let oldInvSums = []
            let oldPresaleSums = []

            for (let i = 0; i < originalOrderItems.length; i++) {
                console.log('IN THE LOOP FOR: ', i)
                const orderItem = originalOrderItems[i]
                if (orderItem.inventoryId) {
                    const match = oldInvSums.find((item) => item.inventoryId === orderItem.inventoryId)
                    console.log('INVENTORY MATCH?: ', match)
                    if ( match ) {
                        match.quantity += Number(orderItem.quantity)
                    } else {
                        oldInvSums.push({inventoryId: orderItem.inventoryId, quantity: orderItem.quantity})
                    }
                }
                if(orderItem.preSaleInd) {
                    const match = oldPresaleSums.find((item) => item.productId === orderItem.productType && item.locationId === orderItem.location)
                    console.log('PRESALE MATCH?: ', match)
                    if ( match ) {
                        match.quantity += Number(orderItem.quantity)
                    } else {
                        oldPresaleSums.push({productId: orderItem.productType, locationId: orderItem.location, quantity: orderItem.quantity})
                    }
                }
            }

            let newInvSums = []
            let newPresaleSums = []

            for (let i = 0; i < salesOrderItems.length; i++) {
                console.log('IN THE NEW LOOP FOR: ', i)
                const orderItem = salesOrderItems[i]
                if (orderItem.inventoryId) {
                    const match = newInvSums.find((item) => item.inventoryId === orderItem.inventoryId)
                    console.log('NEW INVENTORY MATCH?: ', match)
                    if ( match ) {
                        match.quantity += Number(orderItem.quantity)
                    } else {
                        newInvSums.push({inventoryId: orderItem.inventoryId, quantity: orderItem.quantity})
                    }
                }
                if(orderItem.preSaleInd) {
                    const match = newPresaleSums.find((item) => item.productId === orderItem.product && item.locationId === orderItem.location)
                    console.log('NEW PRESALE MATCH?: ', match)
                    if ( match ) {
                        match.quantity += Number(orderItem.quantity)
                    } else {
                        newPresaleSums.push({productId: orderItem.product, locationId: orderItem.location, plantId: orderItem.plantId, quantity: orderItem.quantity})
                    }
                }
            }            
           
            console.log('Inv SUMS: ', oldInvSums)   
            console.log('New Inv SUMS: ', newInvSums)
            console.log('Presale SUMS: ', oldPresaleSums)     
            console.log('New Presale SUMS: ', newPresaleSums)    
            
            // if the whole invoice is set to deleted, then set each of the inventory items to delete
            if (data.orderStatus === '3DL') {
                oldInvSums.forEach((oldInvItem) => {
                    updateInvItems.push({updateType: 'delete', inventoryId: oldInvItem.inventoryId, oldQuantity: oldInvItem.quantity})
                })
                oldPresaleSums.forEach((oldPresaleItem) => {
                    updatePresaleItems.push({updateType: 'delete', inventoryId: oldPresaleItem.inventoryId, oldQuantity: oldPresaleItem.quantity})
                })                
            } else {
                oldInvSums.forEach((oldInvItem) => {
                    console.log('Old Inventory Values: ', oldInvItem)
                    const newInvFound = newInvSums.filter((item) => Number(item.inventoryId) === Number(oldInvItem.inventoryId))
                    if (newInvFound.length) {
                        console.log('WHAT matching record is found???: ', oldInvItem, newInvFound)
                        // same inventory record exists in old and new
                        if ( Number(oldInvItem.quantity) !== Number(newInvFound[0].quantity) ) {
                            // if the quantities are different we need to adjust the inventory for the new quantity on the saved order
                            updateInvItems.push({updateType: 'update', inventoryId: oldInvItem.inventoryId, oldQuantity: oldInvItem.quantity, newQuantity: newInvFound[0].quantity})
                        }
                    } else {
                        // the inventory id was not found in the new records so was deleted and need to adjust inventory to add it back in
                        updateInvItems.push({updateType: 'delete', inventoryId: oldInvItem.inventoryId, oldQuantity: oldInvItem.quantity})
                    }
                })

                newInvSums.forEach((newInvItem) => {
                    console.log('New Inventory values: ', newInvItem)
                    const oldInvFound = oldInvSums.filter((item) => Number(item.inventoryId) === Number(newInvItem.inventoryId))
                    if (!oldInvFound.length) {
                        // the new inventory id wasn't in the old - so it has been added as a new item and we need to adjust inventory
                        updateInvItems.push({updateType: 'added', inventoryId: newInvItem.inventoryId, newQuantity: newInvItem.quantity})
                    }
                })               

                oldPresaleSums.forEach((oldPresaleItem) => {
                    console.log('Old Presale Values: ', oldPresaleItem)
                    const newPresaleFound = newPresaleSums.filter((item) =>(Number(item.productId) === Number(oldPresaleItem.productId)) && (Number(item.locationId) === Number(oldPresaleItem.locationId)))
                    if (newPresaleFound.length) {
                        // same inventory record exists in old and new
                        if ( Number(oldPresaleItem.quantity) !== Number(newPresaleFound[0].quantity) ) {
                            // if the quantities are different we need to adjust the inventory for the new quantity on the saved order
                            updatePresaleItems.push({updateType: 'update', productId: oldPresaleItem.productId, locationId: oldPresaleItem.locationId, oldQuantity: oldPresaleItem.quantity, newQuantity: newPresaleFound[0].quantity})
                        }
                    } else {
                        // the inventory id was not found in the new records so was deleted and need to adjust inventory to add it back in
                        updatePresaleItems.push({updateType: 'delete', productId: oldPresaleItem.productId, locationId: oldPresaleItem.locationId, oldQuantity: oldPresaleItem.quantity})
                    }
                })

                newPresaleSums.forEach((newPresaleItem) => {
                    console.log('New Presale values: ', newPresaleItem)
                    const oldPresaleFound = oldPresaleSums.filter((item) => (Number(item.productId) === Number(newPresaleItem.productId)) && (Number(item.locationId) === Number(newPresaleItem.locationId)))
                    if (!oldPresaleFound.length) {
                        // the new inventory id wasn't in the old - so it has been added as a new item and we need to adjust inventory
                        updatePresaleItems.push({updateType: 'added', productId: newPresaleItem.productId, locationId: newPresaleItem.locationId, newQuantity: newPresaleItem.quantity})
                    }
                })
            }            
         
        }

        console.log('WHAT INV ITEMS NEED UPDATING: ', updateInvItems)
        console.log('WHAT PRESALE ITEMS NEED UPDATING: ', updatePresaleItems)

        setSaving(true)
        let session = await Auth.currentSession()
        let jwt = session.getIdToken().getJwtToken()

        const { username } = await Auth.currentAuthenticatedUser()
        let tzOffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
        let transactionDate = (new Date(Date.now() - tzOffset)).toISOString().slice(0, 19).replace('T', ' ')
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone        

        axios
            .put(`${process.env.REACT_APP_API_URL}/savesalesorder`, {salesOrderData: salesOrderDetail, salesOrderItemData: salesOrderItems, isAddMode, username, transactionDate, timeZone, updateInvItems, updatePresaleItems},
            {headers: {Authorization: jwt}}
            )
            .then(response => {
                setSaving(false)
                setSalesOrderItemData(initSalesOrderItemData)
                setSalesOrderTotal(0)
                setSalesOrderTotalLbs(0)
                setSalesOrderId(response.data.salesOrderId)
                setConfirmationPage(true)
            })
            .catch(error => {
                console.log('The error is: ', error)
                setErrorMsg('Problem saving data: ', error)
                setSaving(false)
            })            
    }

    const resetPage = async () => {
        reset()
        setConfirmationPage(false)
        navigate("/sales/sales-order-report")
    }

    const reverseOrderPage = () => {
        console.log('DID I GET HERE?: ', salesOrderId)
        navigate(
            `/sales/reverse-order/${id}`,
        {
            state: {
                salesData: salesData,
                orderItemData: salesOrderItemData,
                salesValues: salesValues,
                salesOrderId: salesOrderId
            }
        })
    }

    return (
        <div className="report">
            {!confirmationPage && <div>
            <h3>Sales Order</h3>
            { loading && <h3>Loading...</h3> }
            {errorMsg && <p className="form__error">{errorMsg}</p>}
            <form onSubmit={handleSubmit(onSubmit)} onReset={reset}>
            <fieldset disabled={saving}>
            {!isAddMode && <div className="reportdate label_margin_bottom">
                <label>Order #: {String(id).substr(0,4)}-{String(id).substr(4)}</label>
            </div>}
            <div className="reportdate">
                <div>
                    <label className="style_block"><span>Sales Person: </span>
                        <select  
                        id='salesPerson' 
                        {...register('salesPerson')} 
                        disabled={loading || saving || currOrderStatus === '3DL'}
                        className="sales__select">
                            <option key="select" value="">Please Select</option>
                            {salesValues.employeeRows && salesValues.employeeRows.map((employeeRow) => (
                                <option key={employeeRow.employee_id} value={employeeRow.employee_id}>{employeeRow.first_name} {employeeRow.last_name}</option>
                            ))}
                        </select>
                    </label>
                    <span className="validation_error">{errors?.salesPerson?.message}</span>
                </div>
                <div className="label_margin_left">
                    <SalesDate salesDate={salesDate} updateSalesDate={setSalesDate} label="Sales Date"/>
                </div>
                <div>
                    <label className="style_block"><span>Order Status: </span>
                        <select  
                        id='orderStatus' 
                        {...register('orderStatus')} 
                        disabled={loading || saving || currOrderStatus === '3DL'}
                        className="sales__select">
                            <option key="select" value="">Please Select</option>
                            {isAddMode && salesValues.orderStatusRows && salesValues.orderStatusRows
                            .filter((orderStatusRow) => (orderStatusRow.status_code !== '3DL'))
                            .map((orderStatusRow) => (
                                <option key={orderStatusRow.status_code} value={orderStatusRow.status_code}>{orderStatusRow.status_description}</option>
                            ))}
                            {!isAddMode && salesValues.orderStatusRows && salesValues.orderStatusRows.map((orderStatusRow) => (
                                <option key={orderStatusRow.status_code} value={orderStatusRow.status_code}>{orderStatusRow.status_description}</option>
                            ))}
                        </select>
                    </label>
                    <span className="validation_error">{errors?.orderStatus?.message}</span>
                </div>
                { /* Hide the invoice date, invoice #, paid date fields as they aren't required
                    We can unhide later if needed
                */}
                { /*  01/16/2024 - Unhide invoice number and invoice data based on request
                */}
               
                {!isAddMode && <div className="label_margin_left">
                    <label className="style_block">Invoice #:
                        <input 
                            type="char"
                            className="text-input__customer"
                            name="invoiceNumber"
                            {...register('invoiceNumber')}
                            disabled={loading || saving || currOrderStatus === '3DL'}
                        />
                    </label>
                </div>}

                {!isAddMode && <div className="label_margin_left">
                    <SalesDate salesDate={invoiceDate} updateSalesDate={setInvoiceDate} label="Invoiced On"/>
                </div>}

                
                { /*
                {!isAddMode && invoiceDate && <div className="label_margin_left">
                    <SalesDate salesDate={paidDate} updateSalesDate={setPaidDate} label="Paid On"/>
                </div>} */}
            </div>
            <div className="reportdate label_margin_top">
                <div>
                    <label className="style_block">Plant:
                        <select id='plantFacility'
                        {...register('plantFacility')} 
                        disabled={loading || saving || currOrderStatus === '3DL'}
                        className="plant__select">
                            <option key="select" value="">Please Select</option>
                            {salesValues.plantRows && salesValues.plantRows.map((plantRow) => (
                                <option key={plantRow.plant_id} value={plantRow.plant_id}>{plantRow.plant_name}</option>
                            ))}
                        </select>
                    </label>
                    <span className="validation_error">{errors?.plantFacility?.message}</span>
                </div>
                <div className="label_margin_left">
                    <label className="style_block">Customer: 
                        <select id='customer'
                        {...register('customer')} 
                        disabled={loading || saving || currOrderStatus === '3DL'}
                        className="customer__select">
                            <option key="select" value="">Please Select</option>
                            {salesValues.customerRows && salesValues.customerRows.map((customerRow) => (
                                <option key={customerRow.customer_id} value={customerRow.customer_id}>{customerRow.customer_name}</option>
                            ))}
                        </select>
                    </label>
                    <span className="validation_error">{errors?.customer?.message}</span>
                </div>
                <div className="label_margin_left">
                    <label className="style_block">Customer Purchase Order #:
                    <input 
                        type="char"
                        className="text-input__customer"
                        {...register('custPurchaseOrder')}
                        disabled={loading || saving || currOrderStatus === '3DL'}
                    />
                    </label>
                </div>
                <div className="label_margin_left">
                    <label className="style_block">Currency: 
                        <select id='currency'
                        {...register('currency')}
                        disabled={loading || saving || currOrderStatus === '3DL'}
                        className="currency__select" 
                        defaultValue="USD">
                            <option key="USD" value="USD">USD</option>
                            <option key="CAD" value="CAD">CAD</option>
                        </select>
                    </label>
                </div>
            </div>

            {salesOrderItemData.length > 0 &&    
            <div className="table-layout">   
                <table className="styled-table">
                    <thead>
                        <tr>
                            <th>Product Species</th>
                            <th>Product Type</th>
                            <th>Brand Name</th>
                            <th>Location</th>
                            <th>MSC Code</th>
                            <th>BOL</th>
                            <th>Lot #</th>
                            <th>Cases</th>
                            <th className="prod-weight">Product Weight (Lbs)</th>
                            <th className="total-weight">Total Lbs</th>
                            <th>Sale Price / Lb</th>
                            <th className="total-price">Total Price</th>
                            <th><button type="button" className="table-button_invert__small" onClick={addSalesItemRow} ><b>+</b></button></th>
                        </tr>
                    </thead>
                    <tbody>
                        {salesOrderItemData.map((salesOrderItem, index) => (
                            <SalesOrderItemList 
                                key={salesOrderItem.internalIndex}
                                localIndex={salesOrderItem.internalIndex} 
                                salesItemNo={index} 
                                salesItem={salesOrderItem} 
                                salesValues={salesValues}
                                removeSalesItemRow={removeSalesItemRow}
                                calculateSalesOrderTotal={calculateSalesOrderTotal}
                                calculateSalesOrderTotalLbs={calculateSalesOrderTotalLbs}
                                register={register}
                                errors={errors}
                                isAddMode={isAddMode}
                                setValue={setValue}
                                getValues={getValues}
                                currOrderStatus={currOrderStatus}
                            />
                        ))}
                    </tbody>
                    <tfoot>
                        <tr>
                            <th colSpan="9" className="table__footer">SALES ORDER TOTAL: </th>
                            <td>{salesOrderTotalLbs.toLocaleString('en-US')}</td>
                            <td></td>
                            <td>{Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(salesOrderTotal)}</td>
                        </tr>
                    </tfoot>
                </table>
            </div>
            }
            <div className="reportdate">
                <label>Comments:  </label>
                <textarea id="comments" 
                    {...register('comments')} 
                    className="textarea__comments" 
                    disabled={loading || saving || currOrderStatus === '3DL'}/>
                <label className="label_margin_left">Invoice Notes:  </label>
                <textarea id="invoiceNotes" 
                    {...register('invoiceNotes')} 
                    className="textarea__comments" 
                    disabled={loading || saving || currOrderStatus === '3DL'}/>
            </div>
            { saving && <h3>Saving Data...</h3>}
            <div className="reportdate__button formdata">
                <button type="submit" className="button button-space" disabled={loading || saving} onClick={() => navigate("/sales/sales-order-report")}>Cancel</button>                
                {!isAddMode && (cognitoGroups && cognitoGroups.includes('reverse-group')) &&
                    <button 
                        type="button"
                        onClick={reverseOrderPage}
                        className="button button-space" 
                        disabled={loading || saving || currOrderStatus === '3DL'}>Reverse Order
                    </button> 
                }
                <button type="submit" className="button" disabled={loading || saving || currOrderStatus === '3DL'}>{buttonLabel}</button>
            </div>
            </fieldset>
            </form>
        </div>} 

        {confirmationPage && <div className="report">
            {isAddMode && <div className="reportDate label_margin_top">Your order has been submitted. The order number is <b>{String(salesOrderId).substr(0,4)}-{String(salesOrderId).substr(4)}</b></div>}
            {!isAddMode && <div className="reportDate label_margin_top">Order number <b>{String(salesOrderId).substr(0,4)}-{String(salesOrderId).substr(4)}</b> has been updated.</div>}
            <div className="reportDate">An email has been sent with the order details.</div>
            <div className="reportdate__button label_margin_top">
                    <button className="button" disabled={loading} onClick={resetPage}>Go To Sales Order Report</button>
            </div>

        </div>}
        </div>
    )
}

export default SalesOrder