import { useState, useEffect } from 'react'
import { Auth } from 'aws-amplify'
import moment from 'moment'
import axios from 'axios'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import SalesDate from '../common/SalesDate'
import ReverseOrderItemList from './ReverseOrderItemList';
import SalesOrderItem from './SalesOrderItemList';
import { useParams, NavLink, useNavigate, Link, useLocation} from 'react-router-dom';

const ReverseSalesOrder = () => {
    // const [salesOrderId, setSalesOrderId] = useState(orderId)
    const [salesOrderId, setSalesOrderId] = useState()
    const [salesOrderData, setSalesOrderData] = useState()
    const [salesOrderItemData, setSalesOrderItemData] = useState()
    const [salesValues, setSalesValues] = useState()
    const [salesOrderTotal, setSalesOrderTotal] = useState(0)
    const [salesOrderTotalLbs, setSalesOrderTotalLbs] = useState(0)
    const [salesOrderReverseTotal, setSalesOrderReverseTotal] = useState(0)
    const [salesOrderReverseDiff, setSalesOrderReverseDiff] = useState(0)
    const [salesOrderReverseLbs, setSalesOrderReverseLbs] = useState(0)
    const [employeeName, setEmployeeName] = useState('')
    const [customerName, setCustomerName] = useState('')
    const [plantName, setPlantName] = useState('')
    const [errorMsg, setErrorMsg] = useState('')
    const [loading, setLoading] = useState(false)
    const [saving, setSaving] = useState(false)
    const [confirmationPage, setConfirmationPage] = useState(false)
    const [currOrderStatus, setCurrOrderStatus] = useState()

    const { id }  = useParams();
    const navigate = useNavigate()
    let location = useLocation()

    // Get sales values when the page is loaded
    useEffect (() => {
      console.log('LOC IN EFFECT: ', location)
      setSalesOrderData(location.state.salesData)
      setSalesValues(location.state.salesValues)
      setSalesOrderItemData(location.state.orderItemData)
      setSalesOrderId(id)
      console.log('WHAT IS HERE???: ', location.state.salesValues, salesValues)
      const salesPersonData = location.state.salesValues.employeeRows.filter(employee => employee.employee_id == location.state.salesData.salesPerson)
      setEmployeeName(salesPersonData[0].first_name + ' ' + salesPersonData[0].last_name)

      setPlantName(location.state.salesValues.plantRows.filter(plant => plant.plant_id == location.state.salesData.plantFacility)[0].plant_name)
      setCustomerName(location.state.salesValues.customerRows.filter(customer => customer.customer_id == location.state.salesData.customer)[0].customer_name)
      setCurrOrderStatus(location.state.salesValues.orderStatusRows.filter(status => status.status_code == location.state.salesData.orderStatus)[0].status_description)

      setSalesOrderTotal(location.state.salesData.salesOrderTotal)
      setSalesOrderTotalLbs(location.state.salesData.salesOrderTotalLbs)

    }, [])

    // calculate the overall reverse sales order total
    const calculateSalesReverseTotal = () => {
        const totalReverseSalesOrder = salesOrderItemData.reduce((accumulator, object) => {
            return accumulator + object.reverseTotal
        }, 0)        
        setSalesOrderReverseTotal(totalReverseSalesOrder)
    }    

    const calculateSalesReverseTotalLbs = () => {
        const totalReverseOrderLbs = salesOrderItemData.reduce((accumulator, object) => {
            return accumulator + object.reverseWeight
        }, 0)
        setSalesOrderReverseLbs(totalReverseOrderLbs)
    }

    const calculateSalesReverseDiff = () => {
        const totalReverseOrderDiff = salesOrderItemData.reduce((accumulator, object) => {
            return accumulator + object.reverseDiff
        }, 0)
        setSalesOrderReverseDiff(totalReverseOrderDiff)
    }    


    const saveReverseOrder = async (e) => {
        e.preventDefault()
        console.log('WHAT AM I SAVING: ', salesOrderId, salesOrderData, salesOrderItemData)

        const reverseItems = salesOrderItemData.filter(item => Number(item.reverseDiff) !== 0)
            .map(({salesOrderItemId, reverseWeight, reverseSalePrice}) => ({salesOrderItemId, reverseWeight, reverseSalePrice}))

        console.log('Which items made it? ', reverseItems, salesOrderId, salesOrderReverseDiff)        

        setErrorMsg('')
        setSaving(true)
        let session = await Auth.currentSession()
        let jwt = session.getIdToken().getJwtToken()   

        axios
            .put(`${process.env.REACT_APP_API_URL}/reverseorder`, {salesOrderId: salesOrderId, reverseOrderTotal: salesOrderReverseDiff, reverseOrderItems: reverseItems},
            {headers: {Authorization: jwt}}
            )
            .then(response => {
                setSaving(false)
                setConfirmationPage(true)
            })
            .catch(error => {
                console.log('The error is: ', error)
                setErrorMsg('Problem saving data: ', error)
                setSaving(false)
            })            
    }

    const resetPage = () => {
        // reset()
        setConfirmationPage(false)
        navigate("/sales/sales-order-report")
    }

    return (
        <div className="report">
            {!confirmationPage && <div>
            <h3>Reverse Sales Order</h3>
            { loading && <h3>Loading...</h3> }
            {errorMsg && <p className="form__error">{errorMsg}</p>}
            {/* <form onSubmit={handleSubmit(onSubmit)} onReset={reset}> */}

            <div className="reportdate label_margin_bottom">
                <label>Order #: {String(id).substr(0,4)}-{String(id).substr(4)}</label>
            </div>
            <div className="reportdate">
              <div>
                  <label className="style_block">Sales Person: </label>
                  <input disabled={true} defaultValue={employeeName} className="text-input__customer"/>
              </div>
              <div className="label_margin_left">
                  <label className="style_block">Sales Date: </label>
                  <input disabled={true} defaultValue={salesOrderData?.salesDate ? salesOrderData.salesDate : ''} className="text-input__customer"/>
              </div>
              <div className="label_margin_left">
                  <label className="style_block">Order Status: </label>
                  <input disabled={true} defaultValue={currOrderStatus} className="text-input__customer"/>
              </div>   
              <div className="label_margin_left">
                  <label className="style_block">Invoice #: </label>
                  <input disabled={true} defaultValue={salesOrderData?.invoiceNumber ? salesOrderData.invoiceNumber : ''} className="text-input__customer"/>
              </div>    
              <div className="label_margin_left">
                <label className="style_block">Invoice Date: </label>
                <input disabled={true} defaultValue={salesOrderData?.invoiceDate ? salesOrderData.invoiceDate : ''} className="text-input__customer"/>                
              </div>                                
            </div>
            <div className="reportdate label_margin_top">
              <div>
                <label className="style_block">Plant: </label>
                <input disabled={true} defaultValue={plantName} className="text-input__customer"/>
              </div>     
              <div className="label_margin_left">
                <label className="style_block">Customer: </label>
                <input disabled={true} defaultValue={customerName} className="text-input__customer"/>
              </div>   
              <div className="label_margin_left">
                <label className="style_block">Customer Purchase Order #: </label>
                <input disabled={true} defaultValue={salesOrderData?.custPurchaseOrder ? salesOrderData.custPurchaseOrder : ''} className="text-input__customer"/>                  
              </div>      
              <div className="label_margin_left">
                <label className="style_block">Currency: </label>
                <input disabled={true} defaultValue={salesOrderData?.currency ? salesOrderData.currency : ''} className="text-input__customer"/>                 
              </div>                                                           
            </div>

            {salesOrderItemData?.length > 0 &&    
            <div className="table-layout">   
                <table className="styled-table">
                    <thead>
                        <tr>
                            <th>Product Species</th>
                            <th>Product Type</th>
                            <th>Brand Name</th>
                            <th>Location</th>
                            <th>Cases</th>
                            <th className="prod-weight">Product Weight (Lbs)</th>
                            <th className="total-weight">Total Lbs</th>
                            <th>Sale Price / Lb</th>
                            <th className="total-price">Total Price</th>
                            <th className="total-weight">New Total Lbs</th>    
                            <th>New Sale Price / Lb</th>                        
                            <th className="total-price">New Total Price</th>
                            <th className="total-price">Price Difference</th>
                        </tr>
                    </thead>
                    <tbody>
                        {salesOrderItemData.map((salesOrderItem, index) => (
                            <ReverseOrderItemList 
                                key={salesOrderItem.internalIndex}
                                localIndex={salesOrderItem.internalIndex} 
                                salesItemNo={index} 
                                salesItem={salesOrderItem} 
                                salesValues={salesValues}
                                calculateSalesReverseTotal={calculateSalesReverseTotal}
                                calculateSalesReverseTotalLbs={calculateSalesReverseTotalLbs}
                                calculateSalesReverseDiff={calculateSalesReverseDiff}
                                saving={saving}
                            />
                        ))}
                    </tbody>
                    <tfoot>
                        <tr>
                            <th colSpan="6" className="table__footer">SALES ORDER TOTAL: </th>
                            <td>{Number(salesOrderTotalLbs).toLocaleString('en-US')}</td>
                            <td></td>
                            <td>{Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(salesOrderTotal)}</td>
                            <td>{Number(salesOrderReverseLbs).toLocaleString('en-US')}</td>
                            <td></td>
                            <td>{Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(salesOrderReverseTotal)}</td>
                            <td>{Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(salesOrderReverseDiff)}</td>
                        </tr>
                    </tfoot>
                </table>
            </div>
            }
            </div>}
            { saving && <h3>Saving Data...</h3>}
            <div className="reportdate__button formdata">
                <button type="submit" className="button button-space" disabled={loading || saving} onClick={() => navigate(-1)}>Cancel</button>
                <button type="submit" className="button" disabled={loading || saving} onClick={saveReverseOrder}>Save Reverse Order</button>
            </div>   
            {confirmationPage && <div className="report">
                <div className="reportDate label_margin_top">Your reverse order has been saved. The order number is <b>R-{String(salesOrderId).substr(0,4)}-{String(salesOrderId).substr(4)}</b></div>
                <div className="reportdate__button label_margin_top">
                    <button className="button" disabled={loading} onClick={resetPage}>Go To Sales Order Report</button>
                </div>
            </div>}
        </div>
    )
}

export default ReverseSalesOrder