import { useState, useMemo, useEffect } from 'react'
import { Auth } from 'aws-amplify'
import moment from 'moment'
import axios from 'axios'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import { utils, writeFile } from 'xlsx'
import ReportTable from '../common/ReportTable'
import DateRange from '../common/DateRange'
import { NavLink } from 'react-router-dom'

const SalesOrderReport = () => {
    const [startDate, setStartDate] = useState(moment().subtract(1, "months"))
    const [salesValues, setSalesValues] = useState([])
    const [endDate, setEndDate] = useState(moment())
    const [reportData, setReportData] = useState([])
    const [salesPerson, setSalesPerson] = useState('all')
    const [customer, setCustomer] = useState('all')
    const [loading, setLoading] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')

    useEffect(() => {
        const fetchData = async () => {
            await getReportData()
            getSalesValues()
        }

        fetchData()
    }, [])

    const getSalesValues = async () => {
        setErrorMsg('')
        setLoading(true)
        let session = await Auth.currentSession()
        let jwt = session.getIdToken().getJwtToken()
        // setSalesValues([])
        axios
        // .get(`${process.env.REACT_APP_API_URL}/salesvalue`)
        .get(`${process.env.REACT_APP_API_URL}/salesvalue?values=report`,
        {headers: {Authorization: jwt}}
        )
        .then(response => {
            setSalesValues(response.data)
            setLoading(false)
         })
        .catch(error => {
            console.log('The error is: ', error)
            setErrorMsg('Problem retrieving data: ', error)
            setLoading(false)
        })            
    }

    const getReportData = async () => {
        setErrorMsg(null)
        setLoading(true)
        let session = await Auth.currentSession()
        let jwt = session.getIdToken().getJwtToken()
        axios
            .get(`${process.env.REACT_APP_API_URL}/salesorderreport?startDate=${startDate.format('YYYY-MM-DD')}&endDate=${endDate.format('YYYY-MM-DD')}&salesPerson=${salesPerson}&customer=${customer}`,
            {headers: {Authorization: jwt}}
            )
            .then(response => {
                setReportData(response.data.rows)
                setLoading(false)
                if(response.data.rows.length === 0) {
                    setErrorMsg('No Data Found')
                }
            })
            .catch(error => {
                console.log('The error is: ', error)
                setErrorMsg('Problem retrieving data: ', error)
                setLoading(false)
            })   
    }

    const downloadPDF = () => {
        const doc = new jsPDF()
        doc.autoTable({ styles: {halign: 'center'}, html: '#reportData' })
        doc.save(`SalesOrderReport.pdf`)
        // console.log(reportData)
        // console.log(reportData[0].salesOrderTotal)
    }

    const downloadXLSX = () => {
        const wb = utils.book_new();

        // get the data from the table - as this will be the sorted version
        const reportTable = document.getElementById("reportData")
        const ws = utils.table_to_sheet(reportTable, {sheet: 'SalesReport'})
        utils.book_append_sheet(wb, ws, 'Totals')

        // set the column widths for the spreadsheet
        const wscols = [{wch:12}, {wch:11}, {wch:15}, {wch:24}, {wch:26}, {wch:12}, {wch:11}, {wch:11} ]
        ws['!cols'] = wscols

        writeFile(wb, `SalesOrderReport.xlsx`)
    }

    const downloadXLSXDetails = () => {
        const wb = utils.book_new()

        const orderDetails = [];

        // format the reportData for the report
        reportData.forEach((data) => {
            const orderItems = JSON.parse(data.orderItems)
            orderItems.forEach((item) => {
                console.log(data)
                if (!data.reverseOrderNumber) {
                    orderDetails.push({
                        "Invoice #": data.invoiceNumber,
                        "Invoiced On": data.invoiceDate,
                        "Order #": data.salesOrderNumber,
                        "Sales Date": data.salesDate,
                        "Sales Person": data.salesPerson,
                        "Order Status": data.orderStatus,
                        "Plant": data.plantFacility,
                        "Customer": data.customer,
                        "PO #": data.purchaseOrderNum,
                        "Currency": data.currency,
                        "Product Species": item.productSpecies,
                        "Product Type": item.productType,
                        "BOL": item.billOfLading,
                        "Lot #": item.lotNumber,
                        "Cases": item.quantity,
                        "Product Weight (Lbs)": item.productWeight,
                        "Total Lbs": item.totalWeight,
                        "Sale Price/Lb": item.salePrice,
                        "Total Price": item.totalWeight * item.salePrice
                    })
                } else {
                    orderDetails.push({
                        "Invoice #": "-",
                        "Invoiced On": "-",
                        "Order #": data.reverseOrderNumber,
                        "Sales Date": data.salesDate,
                        "Sales Person": data.salesPerson,
                        "Order Status": data.orderStatus,
                        "Plant": data.plantFacility,
                        "Customer": data.customer,
                        "PO #": data.purchaseOrderNum,
                        "Currency": data.currency,
                        "Product Species": item.productSpecies,
                        "Product Type": item.productType,
                        "BOL": item.billOfLading,
                        "Lot #": item.lotNumber,
                        "Cases": item.quantity,
                        "Product Weight (Lbs)": item.productWeight,
                        "Total Lbs": item.reverseTotalWeight,
                        "Sale Price/Lb": item.reverseSalePrice,
                        "Total Price": (item.reverseTotalWeight * item.reverseSalePrice) - (item.totalWeight * item.salePrice)
                    })                    
                }
            })
        })

        const ws = utils.json_to_sheet(orderDetails)

        utils.book_append_sheet(wb, ws, 'Order Details')

        // add formatting to columns as needed
        let range = utils.decode_range(ws['!ref'])
        for (let rowNum = range.s.r + 1; rowNum <= range.e.r; rowNum++) {

            // format Sale Price
            ws[utils.encode_cell({r: rowNum, c:2})].z = '####-####'

            // format quantity / cases
            ws[utils.encode_cell({r: rowNum, c:14})].z = '_-* #,##0_-;-* #,##0_-;_-* "-"??_-;_-@_-'

            // format total lbs
            ws[utils.encode_cell({r: rowNum, c:16})].z = '_-* #,##0_-;-* #,##0_-;_-* "-"??_-;_-@_-'

            // format Sale Price
            ws[utils.encode_cell({r: rowNum, c:17})].z = '$###,##0.00'

            // format Total Price
            ws[utils.encode_cell({r: rowNum, c:18})].z = '$###,##0.00'
        }        

        // set the column widths for the spreadsheet
        const wscols = [{wch:12}, {wch:11}, {wch:11}, {wch:11}, {wch:15}, {wch:15}, {wch:20}, {wch:26}, {wch:9}, {wch:9}, {wch:11}, {wch:26}, {wch:10}, {wch:10}, {wch:8}, {wch:10}, {wch:10}, {wch:10}, {wch:13} ]
        ws['!cols'] = wscols

        writeFile(wb, `SalesOrderReportDetails.xlsx`)
    }

    const columns = useMemo(() => [
        {
            Header: `Sales Order Report`,
            columns: [
                {
                    Header: 'Order #',
                    accessor: row => {
                        return !row.reverseOrderNumber ? 
                            String(row.salesOrderNumber).substr(0,4) + '-' + String(row.salesOrderNumber).substr(4) : 
                            String(row.reverseOrderNumber).substr(0,1) + '-' + String(row.reverseOrderNumber).substr(1,4) + '-' + String(row.salesOrderNumber).substr(4)
                    }
                },
                {
                    Header: 'Sales Date',
                    accessor: 'salesDate'
                },
                {
                    Header: 'Sales Person',
                    accessor: 'salesPerson'
                },
                {
                    Header: 'Plant',
                    accessor: 'plantFacility'
                },
                {
                    Header: 'Customer',
                    accessor: 'customer'
                },
                {
                    Header: 'PO #',
                    accessor: 'purchaseOrderNum',
                    Footer: 'TOTAL: '
                },
                {
                    Header: 'Total Lbs',
                    accessor: row => {
                        return row.salesOrderTotalLbs ? Intl.NumberFormat('en-US', {style: 'decimal'}).format(Number(row.salesOrderTotalLbs)) : '-'
                    },
                    Footer: data => {
                        const total = useMemo(() =>
                            data.rows.reduce((sum, row) => Number(row.original.salesOrderTotalLbs) + sum,0), [data.rows]
                        )
                        return <>{Intl.NumberFormat('en-US', {style: 'decimal'}).format(Number(total))}</>                        
                    }
                },
                {
                    Header: 'Total Order Amount',
                    accessor: row => {
                        return Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(row.salesOrderTotal)
                    },
                    Footer: data => {
                        const total = useMemo(() =>
                            data.rows.reduce((sum, row) => Number(row.original.salesOrderTotal) + sum,0), [data.rows]
                        )
                        return <>{Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(total.toFixed(2))}</>
                    }
                },
                {
                    Header: 'Order Status',
                    accessor: 'orderStatus'
                },
                {
                    Header: '',
                    // // accessor: 'salesOrderTotal'
                    // accessor: row => {
                    //     return <button type="button" className="table-button" onClick={editPage}>Edit</button>
                    // },
                    id: 'edit',
                    Cell: ({row, value}) => {
                        return (!row.original.reverseOrderNumber)
                            ? (<NavLink to={`/sales/sales-order/${row.original.salesOrderNumber}`}>Edit{value}</NavLink>) : '-'
                    }
                }
            ],
            Footer: ''
        }
    ], [])

    return (
        <div className="report">
            <h3>Sales Order Report</h3>
            <div className="reportdate">
                <div className="reportdate__button">
                    <DateRange startDate={startDate} updateStartDate={setStartDate} endDate={endDate} updateEndDate={setEndDate}/>
                </div>
                <label className="label_margin_left">Sales Person:  </label>
                <select className="sales__select text-input__margin" id="salesPerson" onChange={e => setSalesPerson(e.target.value)}>
                    <option key="all" value="all">All</option>
                    {salesValues.employeeRows && salesValues.employeeRows.map((employeeRow) => (
                        <option key={employeeRow.employee_id} value={employeeRow.employee_id}>{employeeRow.first_name} {employeeRow.last_name}</option>
                    ))}
                </select>
                <label className="label_margin_left">Customer:  </label>
                <select className="customer__select text-input__margin" id="customer" onChange={e => setCustomer(e.target.value)}>
                    <option key="all" value="all">All</option>
                    {salesValues.customerRows && salesValues.customerRows.map((customerRow) => (
                        <option key={customerRow.customer_id} value={customerRow.customer_id}>{customerRow.customer_name}</option>
                    ))}
                </select>
                <div className="reportdate__button formdata">
                    <button className="button" onClick={getReportData} disabled={loading}>Generate Report</button>
                </div>
            </div>
            { loading && <h3>Loading...</h3>}
            {errorMsg && <p className="form__error">{errorMsg}</p>}
            <div className="table-layout">
                <div className="button--group">
                    {reportData.length > 0 && <button className="button--link" onClick={downloadPDF}>.pdf</button> }
                    {reportData.length > 0 && <button className="button--link" onClick={downloadXLSX}>.xlsx</button> }
                    {reportData.length > 0 && <button className="button--link" onClick={downloadXLSXDetails}>.xlsx(with Details)</button> }
                </div>
                {reportData.length > 0 && <ReportTable columns={columns} data={reportData} tableId={"reportData"} showFooter={"true"}/>}
            </div>
        </div>
    )
}

export default SalesOrderReport