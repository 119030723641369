import CurrentInventory from '../inventory/CurrentInventory'
import InventoryAdjustmentHistory from '../inventory/InventoryAdjustmentHistory'
import AddInventory from '../inventory/AddInventory'
import MoveInventory from '../inventory/MoveInventory'
import MovementExceptions from '../inventory/MovementExceptions'

const InventoryOptions = [
    {
        title: 'Current Inventory',
        path: 'current-inventory',
        component: <CurrentInventory />,
        authorized: ['inventory-group', 'inventory-read']
    },
    {
        title: 'Inventory Adjustments',
        path: 'inventory-adjustments',
        component: <InventoryAdjustmentHistory />,
        authorized: 'inventory-group'
    },
    {
        title: 'Add New Inventory',
        path: 'add-inventory',
        component: <AddInventory />,
        authorized: 'inventory-group'
    },    
    {
        title: 'Move Inventory',
        path: 'move-inventory',
        component: <MoveInventory />,
        authorized: 'inventory-group'
    },    
    {
        title: 'Movement Exceptions',
        path: 'movement-exceptions',
        component: <MovementExceptions />,
        authorized: 'inventory-group'
    },         
]

export default InventoryOptions
