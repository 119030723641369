import { Fragment, useState } from 'react'
import { useTable, useSortBy } from 'react-table'

const ReportTableNested = ({ columns, data, tableId, showFooter, showExpand, childColumns  }) => {
  
  const [openList, setOpenList] = useState([])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow
  } = useTable({
    columns,
    data
  },
    useSortBy
  )

  const toggleRowOpenList = (id) => {
    const newList = [];
    newList.push(...openList)
    if (newList.includes(id)) {
      const index = newList.indexOf(id)
      newList.splice(index,1)
      setOpenList(newList)
    } else {
      newList.push(id)
      setOpenList(newList)
    }
    console.log('OPEN LIST: ', openList, columns.length)
  }

  return (
    <table className="styled-table" id={tableId} {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {showExpand && <th></th>}
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                {column.render('Header')}
                <span>
                  {column.isSorted
                  ? column.isSortedDesc
                      ? ' 🔽'
                      : ' 🔼'
                  : ''}
                </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row,i) => {
          prepareRow(row)
          return (
            <Fragment key={i + "_frag"}>
              <tr {...row.getRowProps()}>
                {showExpand && 
                  <td>
                    <button type="button" className="table-button__xsmall" onClick={() => toggleRowOpenList(row.id)} >
                      {openList.includes(row.id) ? "-" : "+"}
                    </button>
                  </td>
                }
                {row.cells.map(cell => {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
              {openList.includes(row.id) && 
                <tr colSpan={columns.length}>
                  <td colSpan={columns.length}>
                    <ReportTableNested columns={childColumns} data={data[i].finishedGoodsDetails} tableId="test"/>
                  </td>
                </tr>
              }
            </Fragment>
          )
        })}
      </tbody>
      {showFooter==="true" && 
      <tfoot>
        {footerGroups.map(group => (
          <tr {...group.getFooterGroupProps()}>
            {group.headers.map(column => (
            <td {...column.getFooterProps()}>{column.render('Footer')}</td>
            ))}
          </tr> 
        ))}
      </tfoot>}
    </table>
  )
}

export default ReportTableNested