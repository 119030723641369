import { useState, useEffect } from 'react'
import { Auth } from 'aws-amplify'
import axios from 'axios'
const ProductList = ({productItem, setProductData, setErrorMsg}) => {
  const [editCodeValue, setEditCodeValue] = useState(false)
  const [saving, setSaving] = useState(false)
  const [buttonLabel, setButtonLabel] = useState('Edit')
  const [shortCode, setShortCode] = useState(productItem.short_code)
  const [getInventory, setGetInventory] = useState(productItem.get_inventory)

  useEffect(() => {
    setButtonLabel(editCodeValue ? 'Cancel' : 'Edit')
  }, [editCodeValue])

  const onEditClick = () => {
    setEditCodeValue(!editCodeValue)
    console.log('WHAT IS GETINVENTORY: ', getInventory)
    if (!editCodeValue) {
        setEditCodeValue(productItem.product_id)
    }
  }
  const saveProduct = async (e) => {
    e.preventDefault()

    setSaving(true)
    setErrorMsg(null)
    let session = await Auth.currentSession()
    let jwt = session.getIdToken().getJwtToken()

    const updateType = 'update'
    axios
    .put(`${process.env.REACT_APP_API_URL}/product`, {productId: productItem.product_id, getInventory, shortCode, updateType}, 
    {headers: {Authorization: jwt}}
    )
    .then(response => {
        setSaving(false)
        if (response.data.error) {
            setErrorMsg('Problem saving data: ' + response.data.error)
        } else {
          onEditClick()
          setProductData(response.data.rows)
        }
    }) 
    .catch(error => {
        console.log('The error is: ', error)
        setErrorMsg('Problem saving data: ', error)
        setSaving(false)
    })                 
}

  return (
    <tr key={productItem.product_id}>
        <td>
          {productItem.species_name}
        </td>
        <td>
          {productItem.product_name}
        </td>
        <td>
          {(productItem.weight_type === 'FIX' && 'Fixed')}
          {(productItem.weight_type === 'VAR' && 'Variable')}
          {(productItem.weight_type === 'BLK' && 'Bulk')}
        </td>
        <td>
          {productItem.product_weight}
        </td>
        <td>
          {!editCodeValue && productItem.short_code}
          {editCodeValue &&
            <input 
              type="text"
              className="text-input__customer-add-small"
              name="shortCode"
              value={shortCode}
              disabled={saving}
              onChange={e => setShortCode(e.target.value) } />             
          }
        </td>
        <td>
          {!editCodeValue && productItem.get_inventory === 1 && 'Yes'}
          {!editCodeValue && productItem.get_inventory === 0 && 'No'}
          {editCodeValue &&
            <select className="yes-no__select text-input__margin" id="getInventory" value={getInventory} onChange={e => setGetInventory(e.target.value)}>
              <option key='1' value='1'>Yes</option>
              <option key='0' value='0'>No</option>
            </select>               
          }
        </td>
        <td>
          <button className="table-button__small" onClick={onEditClick} disabled={saving} >{buttonLabel}</button>
        </td>
        <td>
          <button className="table-button__small" onClick={saveProduct} 
              disabled={saving || !editCodeValue } >Save
          </button>
        </td>
    </tr>
  )
}

export default ProductList