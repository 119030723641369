import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Header from '../components/Header'
import WelcomePanel from '../components/WelcomePanel'
import Sales from '../components/top-nav/Sales'
import SalesOrder from '../components/sales/SalesOrder'
import Inventory from '../components/top-nav/Inventory'
import AdministrativePanel from '../components/top-nav/AdministrativePanel'

const AppRouter = () => (
    <BrowserRouter>
        <div>
            <Header />
            <Routes>
                <Route path="/" element={<WelcomePanel />} />
                <Route path="/sales/*" element={<Sales />} />
                <Route path="/inventory/*" element={<Inventory />} />
                <Route path="/admin-panel/*" element={<AdministrativePanel />} />
                <Route path="sales/sales-order/edit/:id" element={<SalesOrder />} />
            </Routes>
        </div>
    </BrowserRouter>
)

export default AppRouter