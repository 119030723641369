import { NavLink, Link} from 'react-router-dom'
import 'react-tabs/style/react-tabs.css'
import logo from '../assets/images/daley-seafoods-logo.png'
import { Auth } from 'aws-amplify'

const Header = () => (
    <header className="header">
        <div className="content-container">
            <div className="header__content">
                <Link to="/">
                    <img className="photo" src={logo} alt="Daley Seafoods logo" />
                </Link>
                <div className="padding">
                    <NavLink to="/sales" className={({ isActive }) => isActive ? "header_title_active" : "header__title"}>Sales Order</NavLink>
                    <NavLink to="/inventory" className={({ isActive }) => isActive ? "header_title_active" : "header__title"} >Inventory</NavLink>
                    <NavLink to="/admin-panel" className={({ isActive }) => isActive ? "header_title_active" : "header__title"} >Admin Panel</NavLink>
                </div>
                <div className="button--group">
                    <button className="button-invert" onClick={signOut}>Log Out</button>
                </div>
            </div>
        </div>
    </header>
)

async function signOut() {
    try {
        await Auth.signOut()
    } catch (error) {
        console.log('error signing out: ', error)
    }
}

export default Header