import { useState, useEffect, useMemo } from 'react'
import { Auth } from 'aws-amplify'
import axios from 'axios'
import { utils, writeFile } from 'xlsx'
import ReportTable from '../common/ReportTableNested'
import moment from 'moment'
import DateRange from '../common/DateRange'

const InventoryAdjustmentHistory = () => {
    const [inventoryAdjustmentData, setInventoryAdjustmentData] = useState([])
    const [adjustmentType, setAdjustmentType] = useState('all')
    const [errorMsg, setErrorMsg] = useState('')
    const [loading, setLoading] = useState(false)
    const [startDate, setStartDate] = useState(moment().subtract(1, "months"))
    const [endDate, setEndDate] = useState(moment())

    useEffect(() => {
        getInventoryAdjustments()
    }, [])

    const getInventoryAdjustments = async () => {
        setErrorMsg('')
        setLoading(true)
        setInventoryAdjustmentData([])
        let session = await Auth.currentSession()
        let jwt = session.getIdToken().getJwtToken()

        axios
            .get(
                `${process.env.REACT_APP_API_URL}/inventory-adjustments?adjustmentType=${adjustmentType}&startDate=${startDate.format('YYYY-MM-DD')}&endDate=${endDate.format('YYYY-MM-DD')}`,
                { headers: { Authorization: jwt } }
            )
            .then((response) => {
                setInventoryAdjustmentData(response.data.rows)
                setLoading(false)
                console.log('WHATS HERE: ', response.data.rows)
            })
            .catch((error) => {
                console.log('The error is: ', error)
                setErrorMsg('Problem retrieving data: ', error)
                setLoading(false)
            })
    }

    const downloadXLSX = () => {
        const wb = utils.book_new();

        // get the data from the table - as this will be the sorted version
        const reportTable = document.getElementById("inventoryAdjustmentData")
        const ws = utils.table_to_sheet(reportTable, {sheet: 'InventoryAdjustments'})
        utils.book_append_sheet(wb, ws, 'Current Inventory')

        // set the column widths for the spreadsheet
        const wscols = [{wch:12}, {wch:26}, {wch:22}, {wch:22}, {wch:11}, {wch:11}, {wch:10}, {wch:10}, {wch:9}, {wch:9}, {wch:20}, {wch: 25}, {wch:12}, {wch:14}, {wch:18} ]
        ws['!cols'] = wscols

        writeFile(wb, `InventoryAdjustments.xlsx`)
    }    


    const summaryColumns = useMemo(
        () => [
            {
                Header: 'Product Species',
                accessor: 'species_name',
            },
            {
                Header: 'Product Type',
                accessor: 'product_name',
            },
            {
                Header: 'Prev Location',
                accessor: 'prev_location',
                Cell: ({ row, value }) => {
                    return (
                      <p style={{ backgroundColor: row.original.new_location !== value && 'red'}}>
                        {value}
                      </p>
                    )
                },                    
            },
            {
                Header: 'New Location',
                accessor: 'new_location',
                Cell: ({ row, value }) => {
                    return (
                      <p style={{ backgroundColor: row.original.prev_location !== value && 'red'}}>
                        {value}
                      </p>
                    )
                },                    
            },
            {
                Header: 'Prev Lot #',
                accessor: 'prev_lot_number',
                Cell: ({ row, value }) => {
                    return (
                      <p style={{ backgroundColor: row.original.new_lot_number !== value && 'red'}}>
                        {value}
                      </p>
                    )
                },    
            },
            {
                Header: 'New Lot #',
                accessor: 'new_lot_number',
                Cell: ({ row, value }) => {
                    return (
                      <p style={{ backgroundColor: row.original.prev_lot_number !== value && 'red'}}>
                        {value}
                      </p>
                    )
                  },                  
            },
            {
                Header: 'Prev BOL',
                accessor: 'prev_bill_of_lading',
                Cell: ({ row, value }) => {
                    return (
                      <p style={{ backgroundColor: row.original.new_bill_of_lading !== value && 'red'}}>
                        {value}
                      </p>
                    )
                },    
            },
            {
                Header: 'New BOL',
                accessor: 'new_bill_of_lading',
                Cell: ({ row, value }) => {
                    return (
                      <p style={{ backgroundColor: row.original.prev_bill_of_lading !== value && 'red'}}>
                        {value}
                      </p>
                    )
                  },                  
            },            
            {
                Header: 'Prev Qty',
                accessor: 'prev_case_quantity',
                Cell: ({ row, value }) => {
                    return (
                      <p style={{ backgroundColor: row.original.new_case_quantity !== value && 'red'}}>
                        {value}
                      </p>
                    )
                  },                
            },
            {
              Header: 'New Qty',
              accessor: 'new_case_quantity',
              Cell: ({ row, value }) => {
                return (
                  <p style={{ backgroundColor: row.original.prev_case_quantity !== value && 'red' }}>
                    {value}
                  </p>
                )
              },
            },
            {
                Header: 'Adjustment Reason',
                accessor: 'adjustment_description',
            },
            {
                Header: 'Comments',
                accessor: 'adjustment_comments',
            },
            {
                Header: 'Changed By',
                accessor: 'adjustment_user',
            },
            {
                Header: 'Date Changed',
                accessor: 'adjustment_time',
            },
            {
                Header: 'Timezone',
                accessor: 'adjustment_time_zone',
            },
        ],
        []
    )

 
    return (
        <div className="report" id="main">
            <h3>Inventory Adjustment History</h3>
            <div className="reportdate">
                <div className="reportdate__button">
                    <DateRange startDate={startDate} updateStartDate={setStartDate} endDate={endDate} updateEndDate={setEndDate}/>
                </div>
                <label className="label_margin_left">Adjustment Type:  </label>
                <select className="customer__select text-input__margin" id="adjustmentType" onChange={e => setAdjustmentType(e.target.value)}>
                    <option key="all" value="all">All</option>
                    <option key="MAN" value="MAN">Manual</option>
                    <option key="AUT" value="AUT">Automatic</option>
                </select>
                <div className="reportdate__button formdata">
                    <button className="button" onClick={getInventoryAdjustments} disabled={loading}>Get History</button>
                </div>
            </div>
    
            {loading && <h3>Loading...</h3>}
            {errorMsg && <p className="form__error">{errorMsg}</p>}

            <div className="table-layout" id="table-data">
                <div className="button--group">
                    {inventoryAdjustmentData.length > 0 && <button className="button--link" onClick={downloadXLSX}>.xlsx</button> }
                </div>                       
                {inventoryAdjustmentData.length > 0 && (
                    <ReportTable
                        columns={summaryColumns}
                        data={inventoryAdjustmentData}
                        tableId={"inventoryAdjustmentData"}
                    />
                )}
            </div>
        </div>
    )
}

export default InventoryAdjustmentHistory
