import { useState, useEffect, useMemo } from 'react'
import { Auth } from 'aws-amplify'
import axios from 'axios'
import { utils, writeFile } from 'xlsx'
import ReportTableNested from '../common/ReportTableNested'
import Modal from 'react-modal'

const CurrentInventory = () => {
    const [finishedGoodsData, setFinishedGoodsData] = useState([])
    const [inventoryValues, setInventoryValues] = useState([])
    const [productSpecies, setProductSpecies] = useState('all')
    const [storageLocation, setStorageLocation] = useState('all')
    const [errorMsg, setErrorMsg] = useState('')
    const [loading, setLoading] = useState(false)
    const [saving, setSaving] = useState(false)
    const [optionsLoading, setOptionsLoading] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)
    const [adjustData, setAdjustData] = useState({})
    const [newStorageLocationId, setNewStorageLocationId] = useState()
    const [newStorageLocationName, setNewStorageLocationName] = useState()
    const [newLotNumber, setNewLotNumber] = useState()
    const [newBillOfLading, setNewBillOfLading] = useState()
    const [newCaseQuantity, setNewCaseQuantity] = useState()
    const [adjustmentReasonId, setAdjustmentReasonId] = useState(null)
    const [adjustmentComments, setAdjustmentComments] = useState('')
    const [cognitoGroups, setCognitoGroups] = useState()

    Modal.setAppElement(document.getElementById('root'))

    useEffect(() => {
        getFinishedGoods()
        getInventoryValues()
    }, [])

    const getCognitoGroups = async () => {
        const {accessToken} = await Auth.currentSession()
        setCognitoGroups(accessToken.payload['cognito:groups'])
        console.log('WHAT COGNITO: ', cognitoGroups)
    }    

    const getFinishedGoods = async () => {
        setErrorMsg('')
        setLoading(true)
        setFinishedGoodsData([])
        getCognitoGroups()
        let session = await Auth.currentSession()
        let jwt = session.getIdToken().getJwtToken()

        axios
            .get(
                `${process.env.REACT_APP_API_URL}/finishedgoods?productSpecies=${productSpecies}&storageLocation=${storageLocation}`,
                { headers: { Authorization: jwt } }
            )
            .then((response) => {
                setFinishedGoodsData(response.data.rows)
                setLoading(false)
                console.log('COG:: ', cognitoGroups)
                console.log('WHATS HERE: ', response.data.rows)
            })
            .catch((error) => {
                console.log('The error is: ', error)
                setErrorMsg('Problem retrieving data: ', error)
                setLoading(false)
            })
    }

    const getInventoryValues = async () => {
        setErrorMsg('')
        setOptionsLoading(true)
        let session = await Auth.currentSession()
        let jwt = session.getIdToken().getJwtToken()

        axios
            .get(
                `${process.env.REACT_APP_API_URL}/salesvalue?values=inventory`,
                { headers: { Authorization: jwt } }
            )
            .then((response) => {
                setInventoryValues(response.data)
                setOptionsLoading(false)
                console.log('INV VALUES: ', response.data)
            })
            .catch((error) => {
                console.log('The error is: ', error)
                setErrorMsg('Problem retrieving data: ', error)
                setLoading(false)
            })
    }

    const downloadXLSX = () => {
        const wb = utils.book_new();

        const inventoryDetails = [];

        finishedGoodsData.forEach((data) => {
            const finishedGoodsDetails = data.finishedGoodsDetails
            finishedGoodsDetails.forEach((detail) => {
                inventoryDetails.push({
                    "Species": data.species_name,
                    "Product": detail.productName,
                    "Storage Location": detail.storageLocationName,
                    "Production Facility": detail.facilityName,
                    "Lot Number": detail.lotNumber,
                    "BOL": detail.billOfLading,
                    "Product Weight": detail.productWeight,
                    "Case Quantity": Number(detail.caseQuantity),
                    "Total Weight": Number(detail.productWeight) * Number(detail.caseQuantity)
                })
            })
        })

        const ws = utils.json_to_sheet(inventoryDetails)

        utils.book_append_sheet(wb, ws, 'Current Inventory')

        // // get the data from the table - as this will be the sorted version
        // const reportTable = document.getElementById("finishedGoodsData")
        // const ws = utils.table_to_sheet(reportTable, {sheet: 'CurrentInventory'})
        // utils.book_append_sheet(wb, ws, 'Totals')

        // set the column widths for the spreadsheet
        const wscols = [{wch:10}, {wch:32}, {wch:26}, {wch:22}, {wch:12}, {wch:12}, {wch:14}, {wch:11}, {wch:11} ]
        ws['!cols'] = wscols

        writeFile(wb, `CurrentInventoryReport.xlsx`)
    }


    const setNewStorageLocation = (e) => {
        setNewStorageLocationId(e.target.value)
        setNewStorageLocationName(e.target.selectedOptions[0].text)
        console.log(e.target.value, e.target.selectedOptions[0].text)
    }

    const openModal = (row) => {
        setModalOpen(true)
        console.log(row)
        setAdjustData({
            finishedGoodsInventoryId: row.finishedGoodsInventoryId,
            productId: row.productId,
            productName: row.productName,
            storageLocationId: row.storageLocationId,
            storageLocationName: row.storageLocationName,
            facilityId: row.facilityId,
            facilityName: row.facilityName,
            lotNumber: row.lotNumber,
            billOfLading: row.billOfLading,
            caseQuantity: row.caseQuantity,
        })
        setNewStorageLocationId(row.storageLocationId)
        setNewStorageLocationName(row.storageLocationName)
        setNewLotNumber(row.lotNumber)
        setNewBillOfLading(row.billOfLading)
        setNewCaseQuantity(row.caseQuantity)
    }

    const closeModal = () => {
        setModalOpen(false)
        console.log('MODAL CLOSED!!!')
        setAdjustData(null)
        setAdjustmentComments(null)
        setAdjustmentReasonId(null)
    }

    const isDataNotChanged = () => {
        if (
            adjustData?.lotNumber == newLotNumber?.trim() &&
            adjustData?.billOfLading == newBillOfLading?.trim() &&
            adjustData?.caseQuantity == newCaseQuantity &&
            adjustData?.storageLocationId == newStorageLocationId
        ) {
            return true
        } else {
            return false
        }
    }

    const saveAdjustment = async () => {
        console.log(adjustmentReasonId)

        if (adjustmentReasonId === null || adjustmentReasonId === 'blank') {
            alert('Please select an Adjustment Reason.')
            return false
        }

        setErrorMsg('')
        setSaving(true)
        console.log('Adjustment Data: ', adjustData)
        console.log('Storage Location: ', newStorageLocationId)
        console.log('Storage Location Name: ', newStorageLocationName)
        console.log('New Lot Number: ', newLotNumber)
        console.log('New BOL: ', newBillOfLading)
        console.log('New Case Quantity: ', newCaseQuantity)
        console.log('Adjustment Reason: ', adjustmentReasonId)
        console.log('Adjustment Comments: ', adjustmentComments)

        let session = await Auth.currentSession()
        let jwt = session.getIdToken().getJwtToken()

        const { username } = await Auth.currentAuthenticatedUser()
        let tzOffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
        let transactionDate = (new Date(Date.now() - tzOffset)).toISOString().slice(0, 19).replace('T', ' ')
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone 

        const newData = {
            storageLocationId: newStorageLocationId,
            lotNumber: newLotNumber,
            billOfLading: newBillOfLading,
            caseQuantity: newCaseQuantity,
            adjustmentReasonId,
            adjustmentComments,
            username,
            transactionDate,
            timeZone,
        }

        console.log(
            'username and timezone: ',
            username,
            transactionDate,
            timeZone
        )

        axios
            .put(
                `${process.env.REACT_APP_API_URL}/finishedgoods`,
                { adjustData, newData },
                { headers: { Authorization: jwt } }
            )
            .then((response) => {
                setSaving(false)
                setAdjustmentReasonId(null)
                setAdjustmentComments(null)

                let newFGData = finishedGoodsData.map((finishedGoodsItem) => {
                    if (finishedGoodsItem.product_id === adjustData.productId) {
                        let updatedQty = 0
                        let newFGDetails = finishedGoodsItem.finishedGoodsDetails.map((finishedGoodsDetail) => {
                            if (finishedGoodsDetail.finishedGoodsInventoryId === adjustData.finishedGoodsInventoryId) {
                                updatedQty += Number(newCaseQuantity)
                                return {
                                    ...finishedGoodsDetail,
                                    caseQuantity: newCaseQuantity,
                                    lotNumber: newLotNumber,
                                    billofLading: newBillOfLading,
                                    storageLocationId:
                                        newStorageLocationId,
                                    storageLocationName:
                                        newStorageLocationName,
                                }
                            } else {
                                updatedQty += Number(
                                    finishedGoodsDetail.caseQuantity
                                )
                                return finishedGoodsDetail
                            }
                        })
                        console.log('NEW QTY: ', updatedQty)
                        return {
                            ...finishedGoodsItem,
                            totalQuantity: updatedQty,
                            finishedGoodsDetails: newFGDetails,
                        }
                    } else {
                        return finishedGoodsItem
                    }
                })
                console.log(newFGData)
                setFinishedGoodsData(newFGData)
                closeModal()
            })
            .catch((error) => {
                console.log('The error is: ', error)
                setErrorMsg('Problem retrieving data: ', error)
                setSaving(false)
            })
    }

    const summaryColumns = useMemo(
        () => [
            {
                Header: 'Product Species',
                accessor: 'species_name',
            },
            {
                Header: 'Product Type',
                accessor: 'product_name',
            },
            {
                Header: 'Product Weight',
                accessor: 'product_weight',
            },
            {
                Header: 'Case Quantity',
                accessor: (row) => {
                    return Intl.NumberFormat('en-US', {style: 'decimal',}).format(Number(row.totalQuantity))
                },
            },
            {
                Header: 'Total Weight',
                accessor: (row) => {
                    return row.product_weight * row.totalQuantity ? Intl.NumberFormat('en-US', {style: 'decimal',}).format(Number(row.product_weight * row.totalQuantity)): '-'
                },
            },
        ],
        []
    )

    const detailColumns = useMemo(
        () => [
            {
                Header: 'Storage Location',
                accessor: 'storageLocationName',
            },
            {
                Header: 'Production Facility',
                accessor: 'facilityName',
            },
            {
                Header: 'Lot Number',
                accessor: 'lotNumber',
            },
            {
                Header: 'BOL',
                accessor: 'billOfLading',
            },
            {
                Header: 'Case Quantity',
                accessor: 'caseQuantity',
            },
            {
                Header: 'Total Weight',
                accessor: (row) => {
                    return row.productWeight * row.caseQuantity ? 
                        Intl.NumberFormat('en-US', {style: 'decimal',}).format(Number(row.productWeight * row.caseQuantity))
                        : '-'
                },
            },
            {
                Header: ' ',
                accessor: (row) => {
                    return <button className="table-button__small" onClick={() => openModal(row)}>
                        Adjust
                    </button>
                },
            },
        ],
        []
    )

    return (
        <div className="report" id="main">
            <h3>Finished Goods Inventory</h3>
            <div className="reportdate">
                <label className="label_margin_left">Product Species: </label>
                <select
                    className="sales__select text-input__margin"
                    disabled={loading || optionsLoading}
                    id="productSpecies"
                    onChange={(e) => setProductSpecies(e.target.value)}
                >
                    <option key="all" value="all">
                        All
                    </option>
                    {inventoryValues?.speciesRows && inventoryValues.speciesRows.map((prodSpeciesRow) => (
                        <option key={prodSpeciesRow.species_id} value={prodSpeciesRow.species_id}>
                            {prodSpeciesRow.species_name}
                        </option>
                    ))}
                </select>
                <label className="label_margin_left">Storage Location: </label>
                <select
                    className="customer__select text-input__margin"
                    disabled={loading || optionsLoading}
                    id="storageLocation"
                    onChange={(e) => setStorageLocation(e.target.value)}
                >
                    <option key="all" value="all">
                        All
                    </option>
                    {inventoryValues?.prodLocationRows && inventoryValues.prodLocationRows.map((storageRow) => (
                        <option key={storageRow.location_id} value={storageRow.location_id}>
                            {storageRow.location_name}
                        </option>
                    ))}
                </select>
                <div className="reportdate__button formdata">
                    <button className="button" onClick={getFinishedGoods} disabled={loading || optionsLoading}>
                        Search
                    </button>
                </div>
            </div>
            {loading && <h3>Loading...</h3>}
            {errorMsg && <p className="form__error">{errorMsg}</p>}
            {/* <table className="styled-table styled-table__fixed">       */}

            <Modal
                isOpen={modalOpen}
                onRequestClose={closeModal}
                className="modal_style"
                shouldCloseOnOverlayClick={false}
            >
                <div className="modal_title">
                    {adjustData?.productName} - Adjustment
                </div>

                {cognitoGroups && !cognitoGroups.includes('inventory-group') &&
                <div className="modal_content">
                    <p>You do not have permissions to adjust inventory</p>
                    <button
                        className="modal-button"
                        onClick={closeModal}
                        disabled={saving}>
                        Cancel
                    </button>
                </div>                    
                }

                {cognitoGroups && cognitoGroups.includes('inventory-group') &&
                <form>
                    <div className="modal_content">
                        <label className="label_margin_left modal_label">
                            Production Facility:{' '}
                        </label>
                        <input
                            value={adjustData?.facilityName}
                            disabled={true}
                            className="modal_readonly_input"
                        />
                    </div>
                    <div className="modal_content">
                        <div>
                            <label className="label_margin_left modal_label style_block">
                                Storage Location:{' '}
                            </label>
                            <label className="label_margin_left modal_label style_block">
                                (orig. {adjustData?.storageLocationName})
                            </label>
                        </div>
                        <select
                            className="modal__select text-input__margin"
                            disabled={saving}
                            value={newStorageLocationId}
                            id="newStorageLocation"
                            onChange={(e) => setNewStorageLocation(e)}
                        >
                            {inventoryValues?.prodLocationRows && inventoryValues.prodLocationRows.map((prodLocationRow) => (
                                <option key={prodLocationRow.location_id} value={prodLocationRow.location_id}>
                                    {prodLocationRow.location_name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="modal_content">
                        <label className="label_margin_left modal_label">
                            Lot Number (orig. value {adjustData?.lotNumber}):{' '}
                        </label>
                        <input
                            value={newLotNumber}
                            className="modal_number_input"
                            disabled={saving}
                            onChange={(e) => setNewLotNumber(e.target.value)}
                        />
                    </div>
                    <div className="modal_content">
                        <label className="label_margin_left modal_label">
                            BOL (orig. value {adjustData?.billOfLading}):{' '}
                        </label>
                        <input
                            value={newBillOfLading}
                            className="modal_number_input"
                            disabled={saving}
                            onChange={(e) => setNewBillOfLading(e.target.value)}
                        />
                    </div>                    
                    <div className="modal_content">
                        <label className="label_margin_left modal_label">
                            Case Quantity (orig. value{' '}
                            {adjustData?.caseQuantity}):{' '}
                        </label>
                        <input
                            value={newCaseQuantity}
                            className="modal_number_input"
                            type="number"
                            disabled={saving}
                            onChange={(e) => setNewCaseQuantity(e.target.value)}
                        />
                    </div>
                    <div className="modal_content">
                        <label className="label_margin_left modal_label">
                            Adjustment Reason:{' '}
                        </label>
                        <select
                            className="modal__select text-input__margin"
                            disabled={saving || isDataNotChanged()}
                            id="adjustmentReasonId"
                            onChange={(e) => setAdjustmentReasonId(e.target.value)}
                        >
                            <option key="blank" value="blank"></option>
                            {inventoryValues?.adjustRows && inventoryValues.adjustRows.map((adjustRow) => (
                                <option key={adjustRow.id} value={adjustRow.id}>
                                    {adjustRow.adjustment_description}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="modal_content">
                        <label className="label_margin_left modal_label">
                            Comments:{' '}
                        </label>
                        <textarea
                            id="adjustmentComments"
                            className="modal__comments"
                            disabled={saving || isDataNotChanged()}
                            value={adjustmentComments}
                            onChange={(e) => setAdjustmentComments(e.target.value)}
                        />
                    </div>
                    <div className="modal-button--group">
                        <button
                            className="modal-button"
                            onClick={closeModal}
                            disabled={saving}>
                            Cancel
                        </button>
                        <button
                            className="modal-button"
                            type="button"
                            onClick={saveAdjustment}
                            disabled={saving || isDataNotChanged()}>
                            Save
                        </button>
                    </div>
                </form>
                }
            </Modal>

            <div className="table-layout" id="table-data">
                <div className="button--group">
                    {finishedGoodsData.length > 0 && <button className="button--link" onClick={downloadXLSX}>.xlsx</button> }
                </div>                
                {finishedGoodsData.length > 0 && (
                    <ReportTableNested
                        columns={summaryColumns}
                        data={finishedGoodsData}
                        tableId={"finishedGoodsData"}
                        showExpand={true}
                        childColumns={detailColumns}
                    />
                )}
            </div>
        </div>
    )
}

export default CurrentInventory
