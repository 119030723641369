import { useState, useEffect, useMemo } from 'react'
import { Auth } from 'aws-amplify'
import axios from 'axios'
import { utils, writeFile } from 'xlsx'
import ReportTable from '../common/ReportTableNested'
import moment from 'moment'
import DateRange from '../common/DateRange'

const MovementExceptions = () => {
    const [movementExceptionData, setMovementExceptionData] = useState([])
    const [errorMsg, setErrorMsg] = useState('')
    const [loading, setLoading] = useState(false)
    const [startDate, setStartDate] = useState(moment().subtract(1, "months"))
    const [endDate, setEndDate] = useState(moment())

    useEffect(() => {
        getMovementExceptions()
    }, [])

    const getMovementExceptions = async () => {
        setErrorMsg('')
        setLoading(true)
        setMovementExceptionData([])
        let session = await Auth.currentSession()
        let jwt = session.getIdToken().getJwtToken()

        setLoading(false)

        axios
            .get(
                `${process.env.REACT_APP_API_URL}/movement-exceptions?startDate=${startDate.format('YYYY-MM-DD')}&endDate=${endDate.format('YYYY-MM-DD')}`,
                { headers: { Authorization: jwt } }
            )
            .then((response) => {
                setMovementExceptionData(response.data.rows)
                setLoading(false)
                console.log('WHATS HERE: ', response.data.rows)
            })
            .catch((error) => {
                console.log('The error is: ', error)
                setErrorMsg('Problem retrieving data: ', error)
                setLoading(false)
            })
    }

    // const downloadXLSX = () => {
    //     const wb = utils.book_new();

    //     // get the data from the table - as this will be the sorted version
    //     const reportTable = document.getElementById("inventoryAdjustmentData")
    //     const ws = utils.table_to_sheet(reportTable, {sheet: 'InventoryAdjustments'})
    //     utils.book_append_sheet(wb, ws, 'Current Inventory')

    //     // set the column widths for the spreadsheet
    //     const wscols = [{wch:12}, {wch:26}, {wch:22}, {wch:22}, {wch:11}, {wch:11}, {wch:10}, {wch:10}, {wch:9}, {wch:9}, {wch:20}, {wch: 25}, {wch:12}, {wch:14}, {wch:18} ]
    //     ws['!cols'] = wscols

    //     writeFile(wb, `InventoryAdjustments.xlsx`)
    // }    

    const summaryColumns = useMemo(
        () => [
            {
              Header: 'Upload Date',
              accessor: 'uploadDate',
            },
            {
              Header: 'Movement Date',
              accessor: 'movementDate',
            },
            {
              Header: 'Exception Reason',
              accessor: 'exceptionDescription',
            },  
            {
              Header: 'Plant',
              accessor: 'PlantName'
            },
            {
              Header: 'Orig Loc',
              accessor: 'OriginLoc'
            },
            {
              Header: 'Dest Loc',
              accessor: 'DestinationLoc'
            },
            {
              Header: 'Orig Lot',
              accessor: 'OrigLot'
            },
            {
              Header: 'Dest Lot',
              accessor: 'DestinationLot'
            },
            {
              Header: 'Orig BOL',
              accessor: 'OrigBOL'
            },
            {
              Header: 'Dest BOL',
              accessor: 'Destination BOL'
            },
            {
              Header: 'Prod Code',
              accessor: 'ProductCode'
            },
            {
              Header: 'Qty',
              accessor: 'CaseQuantity'
            },                                                                                                
        ],
        []
    )

 
    return (
        <div className="report" id="main">
            <h3>Movement Exceptions</h3>
            <div className="reportdate">
                <div className="reportdate__button">
                    <DateRange startDate={startDate} updateStartDate={setStartDate} endDate={endDate} updateEndDate={setEndDate}/>
                </div>
                <div className="reportdate__button formdata">
                    <button className="button" onClick={getMovementExceptions} disabled={loading}>Get Exceptions</button>
                </div>
            </div>
    
            {loading && <h3>Loading...</h3>}
            {errorMsg && <p className="form__error">{errorMsg}</p>}

            <div className="table-layout" id="table-data">
                {/* <div className="button--group">
                    {movementExceptionData.length > 0 && <button className="button--link" onClick={downloadXLSX}>.xlsx</button> }
                </div>                        */}
                {movementExceptionData.length > 0 && (
                    <ReportTable
                        columns={summaryColumns}
                        data={movementExceptionData}
                        tableId={"movementExceptionData"}
                    />
                )}
            </div>
        </div>
    )
}

export default MovementExceptions
