import React from 'react'
import { useEffect, useState } from 'react'
import { Auth } from 'aws-amplify'
import { Route, NavLink, Routes} from 'react-router-dom'

const SubMenu = ({ componentOptions: ComponentOptions }) => {

    const [cognitoGroups, setCognitoGroups] = useState()

    const getCognitoGroups = async () => {
        const {accessToken} = await Auth.currentSession()
        setCognitoGroups(accessToken.payload['cognito:groups'])
    }

    useEffect(() => {
        getCognitoGroups()
    }, [])

    return (
        <div className="menu">
        <div className="flex__container">
            <div className="menu__content">
                <ul>
                    {ComponentOptions.map(({path, title, visibility, authorized}, index) => {
                        if (visibility !== 'hidden') {
                            if (!authorized || (cognitoGroups && cognitoGroups.some(auth => authorized.includes(auth))) ) {
                                return (
                                    <li key={index} className="menu__title">
                                        <NavLink to={path} className={({ isActive }) => isActive ? "menu__title__active" : "menu__title"} >{title}</NavLink>
                                    </li>
                                )
                            }
                        }
                    })}
                </ul>
            </div>
            <div className="flex__report">
                <Routes>
                    {ComponentOptions.map(({path, component, authorized}, index) => {
                        if (!authorized || (cognitoGroups && cognitoGroups.some(auth => authorized.includes(auth))) ) {
                            return (
                                <Route key={index} path={path} element={component}/>
                            )
                        }
                    })}
                </Routes>
            </div>
        </div>
    </div>
    )
}

export default SubMenu