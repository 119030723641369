import { useState, useEffect } from 'react'
import { PutObjectCommand, S3Client } from '@aws-sdk/client-s3'
import { Auth } from 'aws-amplify'
import axios from 'axios'


const AddInventory = () => {
    const [uploading, setUploading] = useState(false)
    const [loading, setLoading] = useState(false)
    const [plantData, setPlantData] = useState()
    const [errorMsg, setErrorMsg] = useState('')
    const [newInvFile, setNewInvFile] = useState(null)
    const [selectedPlantId, setSelectedPlantId] = useState("")

    useEffect (() => {
        getPlants()
    }, [])

    const getPlants = async () => {
        setErrorMsg('')
        setLoading(true)
        setPlantData([])
        let session = await Auth.currentSession()
        let jwt = session.getIdToken().getJwtToken()

        axios
        .get(`${process.env.REACT_APP_API_URL}/plant`,
        {headers: {Authorization: jwt}}
        )
        .then(response => {
            setPlantData(response.data.rows)
            setLoading(false)
        })
        .catch(error => {
            console.log('The error is: ', error)
            setErrorMsg('Problem retrieving data: ', error)
            setLoading(false)
        })            
    }


    const onFileChosen = (e) => {
        const fileExtension = e.target.files[0].name.split('.')[1]
        console.log(fileExtension)

        if (fileExtension === 'csv' || fileExtension === 'CSV') {
            setNewInvFile(e.target.files[0])
            setErrorMsg('')
        } else {
            setNewInvFile(null)
            setErrorMsg('File must be of type csv')
        }
    }

    const uploadFile = async () => {
        console.log('DO THE UPLOAD NOW')
        const date = new Date()
        const year = date.getFullYear()
        const month = ("0" + (date.getMonth() + 1)).slice(-2) 
        const timestamp = year + month + ("0" + date.getDate()).slice(-2) + ("0" + date.getHours()).slice(-2) + ("0" + date.getMinutes()).slice(-2) + ("0" + date.getSeconds()).slice(-2) 
        console.log('WHAT IS A DATE: ', timestamp)
        setUploading(true)
        setErrorMsg('')

        const s3 = new S3Client({
            credentials: {
                accessKeyId: process.env.REACT_APP_ACCESS_KEY,
                secretAccessKey: process.env.REACT_APP_ACCESS_SECRET,
            },
            region: 'us-east-1'            
        })

        const fileName = newInvFile.name.split('.')[0]
        const fileExtension = newInvFile.name.split('.')[1]

        const fullFileName = 'inventory/' + selectedPlantId + '/' + year + '/' + month + '/' + fileName + '_' + timestamp + '.' + fileExtension

        console.log(fullFileName)

        const command = new PutObjectCommand({
            Bucket: process.env.REACT_APP_BUCKET,
            Key: fullFileName,
            Body: newInvFile        
        })

        try {
            const response = await s3.send(command)
            setUploading(false)
            setNewInvFile('')
            document.getElementById('file').value=''            
            setSelectedPlantId('')
            document.getElementById('plantFacility').selectedIndex=0
            setErrorMsg('Your file has now been uploaded, an email will be sent once it has been processed')
            console.log(response)
        } catch (error) {
            console.log('THIS IS THE ERROR: ', error)
        }
    }

    return (
        <div className="report" id="main">
            <h3>Add New Inventory</h3>
            { loading && <h3>Loading...</h3>}            
            <div>
                <label className="style_block">Plant:
                        <select id='plantFacility'
                            disabled={loading || uploading}
                            className="product__select text-input__margin"
                            onChange={(e) => setSelectedPlantId(e.target.value)}>                                                            
                                <option key="select" value="">Please Select</option>
                                {plantData && plantData.map((plantRow) => (
                                    <option key={plantRow.plant_id} value={plantRow.plant_id}>{plantRow.plant_name}</option>
                                ))}
                        </select>
                </label>
            </div>
            <div className="label_margin_top">
                <input 
                    type="file" 
                    id="file"
                    disabled={loading || uploading}
                    onChange={onFileChosen}
                />
            </div>
            {newInvFile && <div className="label_margin_top">
                File Details:
                <ul>
                    <li>Name: {newInvFile.name}</li>
                    <li>Type: {newInvFile.type}</li>
                    <li>Size: {newInvFile.size} bytes</li>
                </ul>                
            </div>}
            <div>
                ** Please note - the file must be in csv format, with columns labels for Production Date and a column label for each of the product short-codes that are managed within the system.
            </div>            
            <div className="label_margin_top ">
                <button
                    className="button"
                    onClick={uploadFile}
                    disabled={!newInvFile || uploading || !selectedPlantId || errorMsg}
                >
                    Upload Inventory
                </button>
            </div>
            {uploading && <div>
                File Uploading.... please wait
            </div>}                
            {errorMsg}
        </div>
    )
}

export default AddInventory