import React, { useState, useEffect, useRef } from 'react'
import Modal from 'react-modal'

const SalesOrderItem = ({localIndex, salesItemNo, salesItem, salesValues, removeSalesItemRow,  
        calculateSalesOrderTotal, calculateSalesOrderTotalLbs, register, errors, setValue, getValues, currOrderStatus }) => {

    const [productList, setProductList] = useState([])
    const [prodWeight, setProdWeight] = useState()
    const [totalWeight, setTotalWeight] = useState()
    const [itemTotal, setItemTotal] = useState()
    const [weightType, setWeightType] = useState()
    const [modalOpen, setModalOpen] = useState(false)
    const [prodSelected, setProdSelected] = useState(null)
    const [prodInventory, setProdInventory] = useState([])
    const [getInventory, setGetInventory] = useState(0)
    const [locationLabel, setLocationLabel] = useState('Select Inventory')
    const [quantityLabel, setQuantityLabel] = useState(salesItem.quantity ? salesItem.quantity: '-')
    const [lotNumberLabel, setLotNumberLabel] = useState(salesItem.lotNumber ? salesItem.lotNumber : '-')
    const [bolLabel, setBolLabel] = useState(salesItem.billOfLading ? salesItem.billOfLading : '-')
    const [savedPreSale, setSavedPreSale] = useState(salesItem.preSaleInd ? true : false)
    const [savedLocationId, setSavedLocationId] = useState(salesItem.location)
    const [savedInventoryId, setSavedInventoryId] = useState(salesItem.inventoryId)
    const [selectedInventoryId, setSelectedInventoryId] = useState(salesItem.inventoryId)
    const [invLocationId, setInvLocationId] = useState(salesItem.location)
    const [invLocationName, setInvLocationName] = useState('')
    const [invLotNumber, setInvLotNumber] = useState(salesItem.lotNumber)
    const [invBillOfLading, setInvBillOfLading] = useState(salesItem.billOfLading)
    const [invQuantity, setInvQuantity] = useState(salesItem.quantity)
    const [savedQuantity, setSavedQuantity] = useState(salesItem.quantity)
    const [invPreSale, setInvPreSale] = useState(salesItem.preSaleInd ? true : false);

    Modal.setAppElement(document.getElementById('root'))

    useEffect(() => {
        recalcValues()
    }, [prodWeight, totalWeight, itemTotal, salesItem])

    const { ...speciesParams} = register(`salesItemData.${localIndex}.species`)
    const { ...prodParams} = register(`salesItemData.${localIndex}.product`)
    const { ...brandParams} = register(`salesItemData.${localIndex}.brandCode`)
    const { ...locParams} = register(`salesItemData.${localIndex}.location`)
    const { ...mscParams} = register(`salesItemData.${localIndex}.mscCode`)
    const { ...bolParams} = register(`salesItemData.${localIndex}.billOfLading`)
    const { ...lotParams} = register(`salesItemData.${localIndex}.lotNumber`)
    const { ...qtyParams} = register(`salesItemData.${localIndex}.quantity`)
    const {...varWeightParams} = register(`salesItemData.${localIndex}.productWeight`)
    const {...totWeightParams} = register(`salesItemData.${localIndex}.totalWeight`)
    const { ...priceParams} = register(`salesItemData.${localIndex}.salePrice`)
    const {...weightTypeParams} = register(`salesItemData.${localIndex}.weightType`)
    const {...inventoryIdParams} = register(`salesItemData.${localIndex}.inventoryId`)
    const {...preSaleIndParams} = register(`salesItemData.${localIndex}.preSaleInd`)

    useEffect(() => {
        // load the filtered product list first
        let filterProductList = salesValues.productRows.filter(item => item.product_species_id == salesItem.species)
        setProductList(filterProductList)

        // set the values in the register
        setValue(`salesItemData.${localIndex}.species`, salesItem.species)
        setValue(`salesItemData.${localIndex}.brandCode`, salesItem.brandCode)
        setValue(`salesItemData.${localIndex}.location`, salesItem.location)
        setValue(`salesItemData.${localIndex}.mscCode`, salesItem.mscCode)
        setValue(`salesItemData.${localIndex}.billOfLading`, salesItem.billOfLading)
        setValue(`salesItemData.${localIndex}.lotNumber`, salesItem.lotNumber)
        setValue(`salesItemData.${localIndex}.quantity`, salesItem.quantity)
        setValue(`salesItemData.${localIndex}.productWeight`, salesItem.productWeight)
        setValue(`salesItemData.${localIndex}.totalWeight`, salesItem.totalWeight)
        setValue(`salesItemData.${localIndex}.salePrice`, salesItem.salePrice)
        setValue(`salesItemData.${localIndex}.inventoryId`, salesItem.inventoryId)
        setValue(`salesItemData.${localIndex}.preSaleInd`, salesItem.preSaleInd)
        setTimeout(() => {
            setValue(`salesItemData.${localIndex}.product`, salesItem.productType)
            // const prodName = getProductName(salesItem.productType)
            const locName = getLocationName(salesItem.location)
            if (locName) {
                setLocationLabel(locName)
                setInvLocationName(locName)
            }
            const prodWeightInfo = getProductWeight(salesItem.productType)
            if (prodWeightInfo.weightType === 'FIX') {
                setProdWeight(prodWeightInfo.productWeight)
            } else {
                setProdWeight(salesItem.productWeight)
            }
            setWeightType(prodWeightInfo.weightType)
            setValue(`salesItemData.${localIndex}.weightType`, prodWeightInfo.weightType)
            if (prodWeightInfo.weightType === 'BLK') {
                setTotalWeight(salesItem.totalWeight)
            }
            setGetInventory(prodWeightInfo.getFromInventory)
            let inventoryRow = salesValues.inventoryRows.find((row) => row.product_id === Number(salesItem.productType))
            console.log('INV: ', inventoryRow)
            setProdInventory([])
            if (inventoryRow) {
                setProdInventory(JSON.parse(inventoryRow?.finishedGoodsDetails))
            }
            setProdSelected(salesItem.productType)

            console.log('PRE SALE: ', invPreSale, salesItem)

            // recalcValues()
        }, 250)
    }, [])

    const onSpeciesChange = (e) => {
        let filterProductList = []
        if (e.target.value !== '' && salesValues.productRows) {
            filterProductList = salesValues.productRows.filter(item => item.product_species_id === Number(e.target.value))
        } 
        setProductList(filterProductList)
        setValue(`salesItemData.${localIndex}.product`, null)
        setProdWeight(null)
        setWeightType(null)
        setValue(`salesItemData.${localIndex}.weightType`, null)
        recalcValues()
    }

    const onProductChange = (e) => {
        let inventoryRow = salesValues.inventoryRows.find((row) => row.product_id === Number(e.target.value))
        setProdInventory([])
        if (inventoryRow) {
            setProdInventory(JSON.parse(inventoryRow?.finishedGoodsDetails))
        }
        console.log('Here is the product inventory: ', prodInventory)

        setProdSelected(e.target.value)

        let prodWeightInfo = getProductWeight(e.target.value)
        salesItem.productWeight = prodWeightInfo.productWeight
        salesItem.weightType = prodWeightInfo.weightType  
        setGetInventory(prodWeightInfo.getFromInventory)
        setSelectedInventoryId(null)
        setSavedInventoryId(null)
        setInvLotNumber(null)
        setInvBillOfLading(null)
        setInvQuantity(null)
        setSavedQuantity(null)
        setSavedPreSale(false)
        setSavedLocationId(null)
        setInvPreSale(false)
        setLocationLabel('Select Inventory')
        setLotNumberLabel('-')
        setBolLabel('-')
        setQuantityLabel('-')
        setValue(`salesItemData.${localIndex}.weightType`, prodWeightInfo.weightType)
        if (weightType !== 'BLK' && prodWeightInfo.weightType === 'BLK') {
            setValue(`salesItemData.${localIndex}.quantity`)
            setQuantityLabel('-')
        }
        setValue(`salesItemData.${localIndex}.product`, e.target.value)
        setValue(`salesItemData.${localIndex}.productWeight`, null)
        setValue(`salesItemData.${localIndex}.totalWeight`, null)
        setProdWeight(prodWeightInfo.productWeight)
        setWeightType(prodWeightInfo.weightType)
        setValue(`salesItemData.${localIndex}.weightType`, prodWeightInfo.weightType)
        recalcValues()
    }

    const onQuantityChange = (value) => {
        setValue(`salesItemData.${localIndex}.quantity`, value)
        recalcValues()
    }

    const onWeightChange = (e) => {
        setValue(`salesItemData.${localIndex}.productWeight`, e.target.value)
        setProdWeight(e.target.value)
        recalcValues()
    }

    const onTotWeightChange = (e) => {
        setValue(`salesItemData.${localIndex}.totalWeight`, e.target.value)
        setTotalWeight(e.target.value)
        salesItem.totalWeight = Number(e.target.value)
        recalcValues()
    }

    const onPriceChange = (e) => {
        setValue(`salesItemData.${localIndex}.salePrice`, e.target.value)
        recalcValues()
    }

    const recalcValues = () => {
        if (weightType !== 'BLK') {
            setTotalWeight(calculateTotalWeight())
        }
        setItemTotal(calculateTotalPrice())
        calculateSalesOrderTotal()
        calculateSalesOrderTotalLbs()
    }

    // function to get product weight 
    const getProductWeight = (productId) => {
        let productWeight = null
        let weightType = null
        let getFromInventory = 0
        let productItem = salesValues.productRows.filter(item => item.product_id == productId)
        if (productItem.length) {
            productWeight = productItem[0].product_weight
            weightType = productItem[0].weight_type
            getFromInventory = productItem[0].get_inventory
        }
        return {productWeight, weightType, getFromInventory}
    }

    const getProductName = (productId) => {
        let productName = null
        let productItem = salesValues.productRows.filter(item => item.product_id == productId)
        if (productItem.length) {
            productName = productItem[0].product_name
        }

        return productName
    }

    const getLocationName = (locationId) => {
        let locationName = null
        console.log(salesValues)
        let locationItem = salesValues.prodLocationRows.filter(item => item.location_id === locationId)
        if (locationItem.length) {
            locationName = locationItem[0].location_name
        }

        return locationName
    }

    const calculateTotalWeight = () => {
        let totalWeight = null
        const values = getValues(`salesItemData.${localIndex}`)
        if (values.quantity && prodWeight) {
            totalWeight = values.quantity * prodWeight
        }
        
        if (weightType) {
            salesItem.totalWeight = totalWeight
        }
        return totalWeight
    }

    const calculateTotalPrice = () => {
        let newTotal = null
        const values = getValues(`salesItemData.${localIndex}`)
        if (values.salePrice && totalWeight) {
            newTotal = values.salePrice * totalWeight
            salesItem.itemTotal = newTotal
        }
        return newTotal
    }

    const openModal = () => {
        console.log(selectedInventoryId)
        setModalOpen(true)
    }

    const closeModal = () => {
        setModalOpen(false)
        console.log('MODAL CLOSED!!!')
    }

    const setSelectedInventory = ({ finishedGoodsInventoryId, storageLocationId, storageLocationName, lotNumber, billOfLading }) => {
        setInvPreSale(false)
        setSelectedInventoryId(finishedGoodsInventoryId)
        setInvLocationId(storageLocationId)
        setInvLotNumber(lotNumber)
        setInvBillOfLading(billOfLading)
        setInvLocationName(storageLocationName)
        setInvQuantity(0)
        // if (selectedInventoryId !== savedInventoryId) {
        //     setInvQuantity(savedQuantity)
        // } else {
        //     setInvQuantity(0)
        // }
    }

    const setPreSaleOption = (inventoryPreSale) => {
        console.log('!!!!!!!: ', inventoryPreSale)
        setInvPreSale(inventoryPreSale)
        if (inventoryPreSale) {
            setSelectedInventoryId(null)
            setInvQuantity(null)
        } else {
            setSelectedInventoryId(savedInventoryId)
            setInvQuantity(savedQuantity)
        }
    }

    const setPreSaleInventoryLocation = (e) => {
        setInvLocationId(e.target.value)
        setInvLocationName(e.target.selectedOptions[0].text)
        setInvLotNumber("PRE-SALE")
        setInvBillOfLading("PLANT")
    }

    const cancelModal = () => {
        setInvPreSale(savedPreSale)
        setSelectedInventoryId(savedInventoryId)
        setInvQuantity(savedQuantity)
        setInvLocationId(savedLocationId)
        setInvLocationName(locationLabel)
        setInvLotNumber(lotNumberLabel)
        setInvBillOfLading(bolLabel)
        console.log('????: ', locationLabel, invLocationName)
        // const values = getValues(`salesItemData.${localIndex}`)
        // setInvQuantity()
        closeModal()
    }

    const saveInventory = () => {
        console.log('IM IN HERE???: ', invLocationName, invLocationId)
        setSavedInventoryId(selectedInventoryId)
        setValue(`salesItemData.${localIndex}.inventoryId`, selectedInventoryId)
        setSavedPreSale(invPreSale)
        setValue(`salesItemData.${localIndex}.preSaleInd`, invPreSale)
        invPreSale ? setSavedLocationId(invLocationId) : setSavedLocationId(null)
        setSavedQuantity(invQuantity)
        setValue(`salesItemData.${localIndex}.location`, invLocationId)
        console.log('Inv Location?: ', invLocationName, locationLabel)
        setLocationLabel(invLocationName)
        setValue(`salesItemData.${localIndex}.lotNumber`, invLotNumber)
        setLotNumberLabel(invLotNumber)
        setValue(`salesItemData.${localIndex}.billOfLading`, invBillOfLading)        
        setBolLabel(invBillOfLading)
        onQuantityChange(invQuantity)
        setQuantityLabel(invQuantity)
        closeModal()
    }

    let [speciesErrorInd, prodErrorInd, brandErrorInd, locErrorInd, qtyErrorInd, weightErrorInd, totWeightErrorInd, priceErrorInd] = [false, false, false, false, false, false, false]

    if (errors && errors.salesItemData) {
        if (errors.salesItemData.indexOf(salesItemNo)) {
            const error = errors.salesItemData[salesItemNo]
            if (error) {
                if (error.species) {
                    speciesErrorInd = true
                }
                if (error.product) {
                    prodErrorInd = true
                }
                if (error.brandCode) {
                    brandErrorInd = true
                }
                if (error.location) {
                    locErrorInd = true
                }
                if (error.quantity) {
                    qtyErrorInd = true
                }
                if (error.productWeight) {
                    weightErrorInd = true
                }
                if (error.totalWeight) {
                    totWeightErrorInd = true
                }                
                if (error.salePrice) {
                    priceErrorInd = true
                }
            }
        }
    }

    const removeRow = async () => {
        await removeSalesItemRow(salesItemNo, localIndex)
    }

    return (
        <><tr>
            <td>
                <select id="species"
                    {...speciesParams}
                    className={"species__select" + (speciesErrorInd ? '_error' : '')}
                    onChange={(e) => onSpeciesChange(e, salesItemNo)}
                    disabled={currOrderStatus === '3DL'}>
                    <option key="select" value="">Please Select</option>
                    {salesValues.speciesRows && salesValues.speciesRows.map((speciesRow) => (
                        <option key={speciesRow.species_id} value={speciesRow.species_id}>{speciesRow.species_name}</option>
                    ))}
                </select>
            </td>
            <td>
                <select id="productType"
                    {...prodParams}
                    className={"product__select" + (prodErrorInd ? '_error' : '')}
                    onChange={e => onProductChange(e)}
                    disabled={currOrderStatus === '3DL'}> 
                    <option key="select" value="">Please Select</option>
                    {productList && productList.map((productRow) => (
                        <option key={productRow.product_id} value={productRow.product_id}>{productRow.product_name}</option>
                    ))}
                </select>
                {weightType && <input type="hidden" {...weightTypeParams} id="weightType" />}
            </td>
            <td>
                <select id="brandCode"
                    {...brandParams}
                    className={"brand__select" + (brandErrorInd ? '_error' : '')}
                    disabled={currOrderStatus === '3DL'}>
                    <option key="select" value="">Please Select</option>
                    {salesValues.brandRows && salesValues.brandRows.map((brandRow) => (
                        <option key={brandRow.brand_code} value={brandRow.brand_code}>{brandRow.brand_name}</option>
                    ))}
                </select>
            </td>
            <td>
                {getInventory === 1 && <button type="button" className="button--link_table" disabled={!prodSelected || currOrderStatus === '3DL'} onClick={openModal}>{locationLabel}</button> }
                {getInventory === 0 && 
                    <select id="location"
                        {...locParams}
                        className={"location__select" + (locErrorInd? '_error' : '')}
                        disabled={currOrderStatus === '3DL'}>
                        <option key="select" value="">Please Select</option>
                        {salesValues.prodLocationRows && salesValues.prodLocationRows.map((locationRow) => (
                            <option key={locationRow.location_id} value={locationRow.location_id}>{locationRow.location_name}</option>
                        ))}
                    </select>
                }
            </td>
            <td>
                <select id="mscCode"
                    {...mscParams}
                    className="msc__select"
                    disabled={currOrderStatus === '3DL'}>
                    <option key="select" value="">None</option>
                    {salesValues.mscRows && salesValues.mscRows.map((mscRow) => (
                        <option key={mscRow.msc_id} value={mscRow.msc_id}>{mscRow.msc_code} ({mscRow.plant_code})</option>
                    ))}
                </select>
            </td>
            <td>
                {getInventory === 1 && <button type="button" className="button--link_table" disabled={!prodSelected || currOrderStatus === '3DL'} onClick={openModal}>{bolLabel}</button> }                
                {getInventory === 0 && 
                    <input id="billOfLading"
                        {...bolParams}
                        type="text"
                        className="text-input__counter" />
                }
            </td>
            <td>
                {getInventory === 1 && <button type="button" className="button--link_table" disabled={!prodSelected || currOrderStatus === '3DL'} onClick={openModal}>{lotNumberLabel}</button> }
                {getInventory === 0 && 
                    <input id="lotNumber"
                        {...lotParams}
                        type="text"
                        className="text-input__counter" />
                }
            </td>
            <td>
                {weightType === 'BLK' && '-'}
                {(weightType !== 'BLK' && getInventory === 1) && <button type="button" className="button--link_table" disabled={!prodSelected || currOrderStatus === '3DL'} onClick={openModal}>{quantityLabel}</button> }
                {((weightType === 'FIX' || weightType === 'VAR') && getInventory === 0) &&
                    <input id="quantity"
                        {...qtyParams}
                        type="text"
                        className={"text-input__counter" + (qtyErrorInd ? '_error' : '')}
                        onChange={e => onQuantityChange(e.target.value)} 
                        disabled={currOrderStatus === '3DL'}/>}
            </td>
            <td>
                {weightType === 'FIX' && prodWeight}
                {weightType === 'BLK' && '-'}
                {weightType === 'VAR' &&
                    <input id="productWeight"
                        {...varWeightParams}
                        type="number"
                        step="0.01"
                        min="0.01"
                        className={"text-input__counter" + (weightErrorInd ? '_error' : '')}
                        onChange={e => onWeightChange(e)} 
                        disabled={currOrderStatus === '3DL'}/>}
            </td>
            <td>
                {weightType !== 'BLK' && totalWeight && totalWeight.toLocaleString('en-US')}
                {weightType === 'BLK' &&
                    <input id="totalWeight"
                        {...totWeightParams}
                        type="number"
                        step="0.01"
                        min="0.01"
                        className={"text-input__counter" + (totWeightErrorInd ? '_error' : '')}
                        onChange={e => onTotWeightChange(e)} 
                        disabled={currOrderStatus === '3DL'}/>}
            </td>
            <td>
                <input id="salePrice"
                    {...priceParams}
                    type="text"
                    // step="0.01"
                    className={"text-input__counter" + (priceErrorInd ? '_error' : '')}
                    onChange={e => onPriceChange(e)} 
                    disabled={currOrderStatus === '3DL'}/>
            </td>
            <td>
                {itemTotal && Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(itemTotal)}
            </td>
            <td>
                <button type="button" className="table-button__small" disabled={!salesItemNo || currOrderStatus === '3DL'} onClick={removeRow}>x</button>
            </td>
        </tr>
        <Modal
            isOpen={modalOpen}
            onRequestClose={closeModal}
            className="modal_style"
            shouldCloseOnOverlayClick={false}
        >
                <div className="modal_title">
                    Available Inventory
                </div>
                <div className="table-layout">   
                <table className="styled-table">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Product Location</th>
                            <th>Lot #</th>
                            <th>Qty Available</th>
                            <th>Quantity</th>
                        </tr>
                    </thead>
                    <tbody>
                        {prodInventory.map((prodInvItem, index) => (
                            <tr>
                                <td>
                                    <input 
                                        type="checkbox"
                                        checked={selectedInventoryId === prodInvItem.finishedGoodsInventoryId}
                                        onChange={e => setSelectedInventory(prodInvItem) }
                                    />
                                </td>
                                <td>
                                    {prodInvItem.storageLocationName}
                                </td>                              
                                <td>
                                    {prodInvItem.lotNumber}
                                </td>
                                <td>
                                    {prodInvItem.caseQuantity}
                                </td>
                                <td>
                                    {selectedInventoryId !== prodInvItem.finishedGoodsInventoryId && '-'}
                                    {selectedInventoryId === prodInvItem.finishedGoodsInventoryId && 
                                    <input 
                                        type="number"
                                        min="0"
                                        max={prodInvItem.caseQuantity}
                                        className="text-input__counter"
                                        value={invQuantity }
                                        onChange={e => setInvQuantity(e.target.value) } />
                                    }
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                </div>
                <div>
                    <input 
                        type="checkbox"
                        name="presale"
                        id="presale"
                        checked={invPreSale}
                        onChange={e => setPreSaleOption(!invPreSale)}/>
                    <label for="presale">Pre-Sale</label>             
                </div>
                {invPreSale && 
                    <div className="table-layout">
                        <table className="styled-table">
                            <thead>
                                <tr>
                                    <th>Production Location</th>
                                    <th>Quantity</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <select
                                            className="location__select"
                                            defaultValue={savedLocationId}
                                            onChange={(e) => setPreSaleInventoryLocation(e)}
                                        >
                                            <option key="select" value="">Please Select</option>
                                            {salesValues.prodLocationRows && salesValues.prodLocationRows
                                                .filter((locationRow) => (locationRow.location_type === 1))
                                                .map((locationRow) => (
                                                <option key={locationRow.location_id} value={locationRow.location_id}>{locationRow.location_name}</option>
                                            ))}
                                        </select>
                                    </td>
                                    <td>
                                        <input id="quantity"
                                        type="number"
                                        min="0"
                                        className="text-input__counter"
                                        value={invQuantity }
                                        onChange={e => setInvQuantity(e.target.value) } />                                    
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                }
                <div>
                    <button class="button-space" onClick={cancelModal}>Cancel</button>
                    <button onClick={saveInventory}>Save</button>
                </div>

        </Modal>
        </>        
    )
}

export default SalesOrderItem