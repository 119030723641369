import SalesOrder from '../sales/SalesOrder'
import SalesOrderReport from '../sales/SalesOrderReport'
import ReverseSalesOrder from '../sales/ReverseSalesOrder'

const SalesOrderOptions = [
    {
        title: 'Sales Order',
        path: 'sales-order',
        component: <SalesOrder />,
        visibility: 'show'
    },
    {
        title: 'Sales Order Report',
        path: 'sales-order-report',
        component: <SalesOrderReport />,
        visibility: 'show'
    },
    {
        title: 'Sales Order Edit',
        path: 'sales-order/:id',
        component: <SalesOrder />,
        visibility: 'hidden'
    },
    {
        title: 'Reverse Sales Order',
        path: 'reverse-order/:id',
        component: <ReverseSalesOrder />,
        visibility: 'hidden'
    },
]

export default SalesOrderOptions