import React, { useState, useEffect, useRef } from 'react'
import Modal from 'react-modal'

const ReverseOrderItemList = ({localIndex, salesItemNo, salesItem, salesValues, 
        calculateSalesReverseTotal, calculateSalesReverseTotalLbs, calculateSalesReverseDiff, saving }) => {

    const [productList, setProductList] = useState([])
    const [reverseWeight, setReverseWeight] = useState(salesItem.reverseWeight ? salesItem.reverseWeight : salesItem.totalWeight)
    const [reverseSalePrice, setReverseSalePrice] = useState(salesItem.reverseSalePrice ? salesItem.reverseSalePrice: salesItem.salePrice)
    const [reverseTotal, setReverseTotal] = useState(Number(salesItem.reverseWeight ? salesItem.reverseWeight : salesItem.totalWeight) * Number(salesItem.reverseSalePrice ? salesItem.reverseSalePrice : salesItem.salePrice))
    const [priceDiff, setPriceDiff] = useState()

    useEffect(() => {
        // load the filtered product list first
        let filterProductList = salesValues.productRows.filter(item => item.product_species_id == salesItem.species)
        setProductList(filterProductList)
    }, [])

    const recalcValues = () => {
        calculateSalesReverseTotal()
        calculateSalesReverseTotalLbs()
        calculateSalesReverseDiff()
    }

    useEffect(() => {
        setPriceDiff(Number(reverseTotal) - Number(salesItem.itemTotal))
        salesItem.reverseDiff = (Number(reverseTotal) - Number(salesItem.itemTotal))
        recalcValues()
    },  [reverseTotal])

    useEffect(() => {
        setReverseTotal(Number(reverseWeight) * Number(reverseSalePrice))
        salesItem.reverseTotal = (Number(reverseWeight) * Number(reverseSalePrice))
        salesItem.reverseWeight = Number(reverseWeight)
        salesItem.reverseSalePrice = Number(reverseSalePrice)
        recalcValues()
    }, [reverseWeight, reverseSalePrice])    

    return (
        <><tr>
            <td>
                <select id="species"
                    defaultValue={salesItem.species}
                    className={"species__select"}
                    disabled={true}
                >
                    {salesValues.speciesRows && salesValues.speciesRows.map((speciesRow) => (
                        <option key={speciesRow.species_id} value={speciesRow.species_id}>{speciesRow.species_name}</option>
                    ))}
                </select>
            </td>
            <td>
                <select id="productType"
                    defaultValue={salesItem.product}
                    className={"product__select"}
                    disabled={true}
                >
                    {productList && productList.map((productRow) => (
                        <option key={productRow.product_id} value={productRow.product_id}>{productRow.product_name}</option>
                    ))}
                </select>
            </td>
            <td>
                <select id="brandCode"
                    defaultValue={salesItem.brandCode}
                    className={"brand__select"}
                    disabled={true}
                >
                    {salesValues.brandRows && salesValues.brandRows.map((brandRow) => (
                        <option key={brandRow.brand_code} value={brandRow.brand_code}>{brandRow.brand_name}</option>
                    ))}
                </select>
            </td>
            <td>
                <select id="location"
                    defaultValue={salesItem.location}
                    className={"location__select"}
                    disabled={true}
                >
                    {salesValues.prodLocationRows && salesValues.prodLocationRows.map((locationRow) => (
                        <option key={locationRow.location_id} value={locationRow.location_id}>{locationRow.location_name}</option>
                    ))}
                </select>
            </td>
            <td>
                <input id="quantity"
                    defaultValue={salesItem.quantity}
                    type="text"
                    className={"text-input__counter"}
                    disabled={true} 
                />
            </td>
            <td>
                <input id="prodWeight"
                    defaultValue={salesItem.productWeight}
                    type="text"
                    className={"text-input__counter"}
                    disabled={true} 
                />
            </td>
            <td>
                <input id="totalWeight"
                    defaultValue={salesItem.totalWeight}
                    type="text"
                    className={"text-input__counter"}
                    disabled={true} 
                />
            </td>
            <td>
                <input id="salePrice"
                    defaultValue={salesItem.salePrice}
                    type="text"
                    className={"text-input__counter"}
                    disabled={true} 
                />
            </td>            
            <td>
                { Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(salesItem.itemTotal)}
            </td>
            <td>
                <input id="reverseWeight"
                    defaultValue={reverseWeight}
                    type="text"
                    className={"text-input__counter"}
                    onChange={e => setReverseWeight(e.target.value)}
                    disabled={saving} 
                />
            </td>
            <td>
                <input id="salePrice"
                    defaultValue={reverseSalePrice}
                    type="text"
                    className={"text-input__counter"}
                    onChange={e => setReverseSalePrice(e.target.value)}
                    disabled={saving} 
                />
            </td>  
            <td>
                { Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(reverseTotal)}
            </td>
            <td>
                { Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(priceDiff)}
            </td>                      
        </tr>
        </>        
    )
}

export default ReverseOrderItemList