import { useState, useEffect } from 'react'
import { Auth } from 'aws-amplify'
import axios from 'axios'
const AdjustmentList = ({adjustmentItem, setAdjustmentData, setErrorMsg}) => {
  const [editCodeValue, setEditCodeValue] = useState(false)
  const [saving, setSaving] = useState(false)
  const [newAdjustmentReason, setNewAdjustmentReason] = useState(adjustmentItem.adjustment_description)
  const [buttonLabel, setButtonLabel] = useState('Edit')

  useEffect(() => {
    setButtonLabel(editCodeValue ? 'Cancel' : 'Edit')
  }, [editCodeValue])

  const onEditClick = () => {
    setEditCodeValue(!editCodeValue)
    if (!editCodeValue) {
        setEditCodeValue(adjustmentItem.id)
    }
  }
  const saveAdjustmentReason = async (e) => {
    e.preventDefault()

    setSaving(true)
    setErrorMsg(null)
    let session = await Auth.currentSession()
    let jwt = session.getIdToken().getJwtToken()

    const updateType = 'update'
    axios
    .put(`${process.env.REACT_APP_API_URL}/adjustmentreasons`, {adjustmentId: adjustmentItem.id, adjustmentReason: newAdjustmentReason, updateType}, 
    {headers: {Authorization: jwt}}
    )
    .then(response => {
        setSaving(false)
        if (response.data.error) {
            setErrorMsg(response.data.error)
        } 
        onEditClick()
        setAdjustmentData(response.data.rows)
    }) 
    .catch(error => {
        console.log('The error is: ', error)
        setErrorMsg('Problem saving data: ', error)
        setSaving(false)
    })                 
}

  return (
    <tr key={adjustmentItem.id}>
        <td>
          {!editCodeValue && adjustmentItem.adjustment_description}
          {editCodeValue &&
            <input 
            type="text"
            min="0"
            className="text-input__customer-add"
            name="adjustmentReason"
            value={newAdjustmentReason || ''}
            disabled={saving}
            onChange={e => setNewAdjustmentReason(e.target.value) } />
          }
        </td>
        <td>
          <button className="table-button__small" onClick={onEditClick} disabled={saving} >{buttonLabel}</button>
        </td>
        <td>
          <button className="table-button__small" onClick={saveAdjustmentReason} 
              disabled={saving || !editCodeValue || !newAdjustmentReason} >Save
          </button>
        </td>
    </tr>
  )
}

export default AdjustmentList